import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { AppsettingsService } from '../../services/appsettings.service';


import { IAppData } from 'src/app/services/interfaces/iapp-data';

@Component({
  selector: 'app-appsettings',
  templateUrl: './appsettings.component.html',
  styleUrls: ['./appsettings.component.scss']
})
export class AppsettingsComponent implements OnInit {
  appdata: IAppData = this.appsettingsservice.getAppsettings();
  isDarkTheme: Observable<boolean>;
  isPanelopened: Observable<boolean>;
  isHeaderActive: BehaviorSubject<boolean>;
  isShowBannerActive: BehaviorSubject<boolean>;

  constructor(  public appsettingsservice: AppsettingsService) 
   {    
     this.isDarkTheme = this.appsettingsservice.isDarkTheme;
     this.isHeaderActive = this.appsettingsservice._isHeaderActive;
     this.isShowBannerActive = this.appsettingsservice._isShowBannerActive;

   }

  ngOnInit() {  

  }
  
  toggleShowBanner(checked: boolean){
    //this.appdata.darkmode=!this.appdata.darkmode;
    
    console.log(checked, this.appsettingsservice.isHeaderActive)
    this.appsettingsservice.toggleShowBannerActive();
    //console.log(this.appsettingsservice.getAppsettings());
  

  }


  toggleHeader(checked: boolean){
    //this.appdata.darkmode=!this.appdata.darkmode;
    
    console.log(checked, this.appsettingsservice.isHeaderActive)
    this.appsettingsservice.toggleHeaderActive();
    //console.log(this.appsettingsservice.getAppsettings());
  

  }

  toggleDarkMode(checked: boolean){
    //this.appdata.darkmode=!this.appdata.darkmode;
    
    console.log(checked, this.appsettingsservice.isDarkTheme)
    this.appsettingsservice.setDarkTheme(checked);
    //console.log(this.appsettingsservice.getAppsettings());
  



    if(checked){
      /* here themeTag is id of body tag and dark-theme is css class created in theme file */
      let theme= 'dark-theme';
     // this.overlayContainer.getContainerElement().classList.add('candy-app-dark-theme');
     // this.componentCssClass = theme;
  
  
  
    }else{
     // this.overlayContainer.getContainerElement().classList.add('candy-app-light-theme');
    }
  }

}
