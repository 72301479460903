import { Component, OnInit } from '@angular/core';
import { ResumeproviderService  } from 'src/app/services/resumeprovider.service';

@Component({
  selector: 'app-biography',
  templateUrl: './biography.component.html',
  styleUrls: ['./biography.component.scss']
})
export class BiographyComponent implements OnInit {

  biography: any;

  constructor(resumeproviderService: ResumeproviderService) {

      this.biography=resumeproviderService.biography;
                
   }

  ngOnInit() {
  }

}
