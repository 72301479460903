import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {MatChipsModule, MatSelectModule} from '@angular/material';
import {MatCheckboxModule} from '@angular/material';

import {VgCoreModule} from 'videogular2/core';
import {VgControlsModule} from 'videogular2/controls';
import {VgOverlayPlayModule} from 'videogular2/overlay-play';
import {VgBufferingModule} from 'videogular2/buffering';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavmainComponent } from './navmain/navmain.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule, MatButtonModule, MatSidenavModule,
   MatIconModule, MatListModule, MatGridListModule, MatCardModule, 
   MatMenuModule, MatProgressSpinnerModule, MatSliderModule,
   MatExpansionModule, MatFormFieldModule, MatSlideToggleModule, MatOptionModule ,
  MatPaginatorModule, MatTreeModule,
  } from '@angular/material';
   import {SlideshowModule} from 'ng-simple-slideshow';
   import {RatingModule} from "ngx-rating";
   //import {MatSort, MatTableDataSource} from '@angular/material';
   import {MatSortModule, MatTableModule} from '@angular/material';
 
import { DashboardComponent } from './dashboard/dashboard.component';
import { MusicComponent } from './music/music.component';
import { SoftwareComponent } from './software/software.component';

import { ClubeventsComponent } from './music/clubevents/clubevents.component';
import { ReferencesComponent } from './references/references.component';
import { ProductionComponent } from './music/production/production.component';
import { VideoComponent } from './music/video/video.component';
import { CommercialComponent } from './music/commercial/commercial.component';
import { WerbebannerComponent } from './werbebanner/werbebanner.component';

import { WebsitesComponent } from './websites/websites.component';
import {FlexLayoutModule} from "@angular/flex-layout";
//import { VerticalTimelineModule } from 'angular-vertical-timeline';
import { MglTimelineModule } from 'angular-mgl-timeline';
import { YoutubeComponent } from './music/youtube/youtube.component';
import { SkillsComponent } from './dashboard/skills/skills.component';
import { BiographyComponent } from './dashboard/biography/biography.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DsgvoComponent } from './dsgvo/dsgvo.component';
import { HobbiesComponent } from './dashboard/hobbies/hobbies.component';

import { SortpipePipe } from './utilities/sortpipe.pipe';
import { SoftskillsComponent } from './dashboard/softskills/softskills.component';
import { AppsettingsComponent } from './custom/appsettings/appsettings.component';
import { FooterComponent } from './navmain/footer/footer.component';
import { SafepipePipe } from './utilities/safepipe.pipe';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { SelectpipePipe } from './utilities/selectpipe.pipe';
import { IskillslanguagepipePipe } from './utilities/iskillslanguagepipe.pipe';
import { ClickStopPropagationDirective } from './utilities/click-stop-propagation.directive';
import { FullscreenpictureComponent } from './fullscreenpicture/fullscreenpicture/fullscreenpicture.component';
import { LazyLoadImagesDirective } from './utilities/lazy-load-images.directive';


@NgModule({
  declarations: [
    AppComponent,
    NavmainComponent,
    DashboardComponent,
    MusicComponent,
    SoftwareComponent,
    ClubeventsComponent,
    ReferencesComponent,
    ProductionComponent,
    VideoComponent,
    CommercialComponent,
    WebsitesComponent,WerbebannerComponent,
    YoutubeComponent,
    SkillsComponent,
    BiographyComponent,
    ImpressumComponent,
    DsgvoComponent,
    HobbiesComponent, SortpipePipe, SoftskillsComponent, AppsettingsComponent, FooterComponent, SafepipePipe, SelectpipePipe, IskillslanguagepipePipe, ClickStopPropagationDirective, FullscreenpictureComponent,
    // LazyLoadImagesDirective, 
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatCheckboxModule,
    MatMenuModule,MatChipsModule,MatProgressSpinnerModule, MatExpansionModule,
    MatSliderModule, MatFormFieldModule,  MatSlideToggleModule,MatOptionModule,
     MatSelectModule,MatPaginatorModule,MatTreeModule,
    //MatSort, MatTableDataSource,
    MatSortModule, MatTableModule,
	BrowserModule,FlexLayoutModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
     //   VerticalTimelineModule,
        MglTimelineModule,
        RatingModule,SlideshowModule,MatButtonToggleModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas:[],
})
export class AppModule { }
