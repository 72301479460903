import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FullscreenpictureserviceService {
  
  
  
  
  public pictureUrl: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public pictureUrls: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>([]);

  showPicture(url){
    this.pictureUrl.next(url);
    this.pictureUrls.next([]);
  }  

  hidePicture() {
    this.pictureUrl.next("");
    this.pictureUrls.next([]);
  }


  showPictures(urls){
    this.pictureUrl.next("");

    this.pictureUrls.next(urls);
  }  

  hidePictures() {
    this.pictureUrls.next([]);
    this.pictureUrl.next("");
  }

fullScreenExit(){

  this.hidePictures() ;
}


  constructor() { }
}
