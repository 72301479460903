import { Component, OnInit, ViewChild } from '@angular/core';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { MatSort, MatTableDataSource} from '@angular/material';
import { updateBinding } from '@angular/core/src/render3/instructions';
import { checkAndUpdateTextInline } from '@angular/core/src/view/text';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResumeproviderService, ISkills } from 'src/app/services/resumeprovider.service';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss'],
})
export class SkillsComponent implements OnInit {

  constructor(private breakpointObserver: BreakpointObserver,
    resumeproviderservice:ResumeproviderService) {


      this.skills= resumeproviderservice.skills;
      this.length=this.skills.length;
      this.dataSource= new MatTableDataSource(this.skills);
      this.isCompactview.subscribe(val=> console.log(val));
   }


  skills: Array<ISkills>;

itemsperpage=50;
  pageend:number=this.itemsperpage;
  pagestart:number=0;
length:number 
pageSize:number=this.itemsperpage;
pageSizeOptions: number[] = [5, 10, 15, 50];
  sortcolumn:string;
  sortcolumnorder:number;
  sortcolumns:Array<string>=["order","topic","level","language"];
therowheight:string="50px";

isCompactview: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);


selectedCategory:string="all";
selectedLanguage:string="all";

selectedCategoryChanged(): void{

 console.log(this.selectedCategory);
 this.selectedLanguage="all";

 this.pageend=(0+1)*this.pageSize;
 this.pagestart=(0)*this.pageSize;
 
}

selectedLanguageChanged(): void{

  this.selectedCategory="all";
  this.pageend=(0+1)*this.pageSize;
  this.pagestart=(0)*this.pageSize;

}
 

compactView_clicked($event): void{
  console.log($event, $event.value)
    this.isCompactview.next($event.value)
  //this.isCompactview=!this.isCompactview;
 // if(this.isCompactview ==false){this.therowheight="120px"}
 // else{this.therowheight="50px"}
}

updateview(): void { }

pageChanged(event_):void{
console.log(event_);
this.pageend=(event_.pageIndex+1)*event_.pageSize;
this.pagestart=(event_.pageIndex)*event_.pageSize;
this.length=this.skills.length;

}

 sortorder=-1;
  displayedColumns: string[] = ['topic', 'level', 'language'];
  dataSource: MatTableDataSource<ISkills>;

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.sortcolumnorder=1;
    this.sortcolumn="order";

  }
 
  thumblabel=true
  rowcols=2;
  cardcols=6;
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    let self=this;
  }

  isSmallSet$  = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Small]).subscribe(
    ( result ) => {
     // console.log("Handset",result);

     if(result.matches==true){
      this.thumblabel=true;

       this.cardcols=6;}
    // }else {this.cardcols=2;}
      
  //    this.cards = cards;
    });
  
    isMediumSet$  = this.breakpointObserver.observe([Breakpoints.Medium]).subscribe(
    ( result ) => {
    //console.log("Medium",result);
     
     if(result.matches==true){
       this.thumblabel=true;
       this.cardcols=3;
     }
  //    this.cards = cards;
    });
  

   isLargeSet$ = this.breakpointObserver.observe([Breakpoints.Large]).subscribe(
    ( result ) => {
   // console.log("Large",result);
     
     if(result.matches==true){
       this.thumblabel=true;
       this.cardcols=2;
     }
      
  //    this.cards = cards;
    });
  

  objectKey(obj) {
    return Object.keys(obj);
  }

  formatedCerts() {
      return this.skills.reduce((prev, now) => {
        if (!prev[now.maincat]) {
          prev[now.maincat] = [];
        }

        prev[now.maincat].push(now);
        return prev;
      }, {});

    /*
       Now your data : { "1050 AJ": [ .... ], "X332.0 AC": [...], ... }
    */

  }


  
}
