
import {
    trigger, state,
    animate,
    transition,
    style,
    query
  } from '@angular/animations';
  
  export const divBannerSlideAnimation = trigger('divBannerSlideAnimation', [
    
      state('false', style({height: '0%' , width: '0%' })),   
      
      state('true',  style({height: '100%',width: '50%' })),
      
  transition ('false <=> true', animate('300ms ease-in-out'))
    
  ]);


  export const divBannerSlideAnimationHandset = trigger('divBannerSlideAnimationHandset', [
    
    state('false', style({ height: '0%' , width:"0%"})),   
    
    state('true',  style({ height: '100%', width:"100%" })),
    
transition ('false <=> true', animate('300ms ease-in-out'))
  
]);


export const divBannerSlideHeight = trigger('divBannerSlideHeight', [
    
  state('false', style({ 'height': '0%' })),   
  
  state('true',  style({ 'height': '100%' })),
  
transition ('false <=> true', animate('300ms ease-in-out'))

]);

