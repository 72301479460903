import { Component, HostBinding, HostListener } from '@angular/core';
import { fadeAnimation } from './animations/fadeAnimation';
import { slideAnimation } from './animations/slideAnimation';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { slideInOutAnimation } from './animations/slideInOutAnimation';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { OverlayContainer} from '@angular/cdk/overlay';
import { AppsettingsService } from './services/appsettings.service';

import { Router, NavigationEnd } from '@angular/router';
import { applySourceSpanToStatementIfNeeded } from '@angular/compiler/src/output/output_ast';
import { IAppData } from './services/interfaces/iapp-data';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { divBannerSlideAnimation,divBannerSlideAnimationHandset } from './animations/divBannerSlideAnimation';
import { WindowScrollService } from './services/window-scroll.service';
import {fadeoutTopPanelButtonAnimation} from './animations/fadeoutTopPanelButtonAnimation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'], 
  animations: [fadeAnimation,slideAnimation, slideInOutAnimation, divBannerSlideAnimation ,divBannerSlideAnimationHandset,fadeoutTopPanelButtonAnimation ], // register the animation
  providers:[AppsettingsService]
})



export class AppComponent {
  isDarkTheme: Observable<boolean>;
  isHeaderActive: BehaviorSubject<boolean>;
  isShowBannerActive: BehaviorSubject<boolean>;
  scroll:number = 0;


  appdata:IAppData = this.appsettingsservice.getAppsettings();/* {
    title: 'Georg Turecka',
    headerimage_urls:["/assets/topbanner/comradednb_18NOV2017_fullres_22of30.JPG",
                      "/assets/topbanner/serag-66.jpg", 
                      "/assets/topbanner/serag-72.jpg",
                     // "/assets/topbanner/20160830_OpticalEngineers_SC_0412_web.jpg" ,
                     
                      "/assets/topbanner/img_5307_16_cropped.jpg"  ],
    darkmode:true
  }
*/
/*
  isnotHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => !result.matches)
  );*/
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
      
       map(result => result.matches)
  );

  currentBannerUrl: Observable<SafeResourceUrl> ; 


@HostBinding('class') componentCssClass;

async toppanelbutton_clicked(_event){
  if (_event.button === 0) {
    console.log('Left button clicked'); 
     this.appsettingsservice.toggleHeaderActive();

  }

  console.log(_event)
 //this.appsettingsservice.setHeaderActive(!this.isHeaderActive.getValue())
  


}



constructor(private breakpointObserver: BreakpointObserver,
   public overlayContainer: OverlayContainer,
  public appsettingsservice: AppsettingsService,
  public router: Router,
  public scrollService : WindowScrollService,
  ) {  
    this.isDarkTheme = this.appsettingsservice.isDarkTheme ;
    this.isHeaderActive = this.appsettingsservice._isHeaderActive;
    this.currentBannerUrl = appsettingsservice.BannerURL;
    this.isShowBannerActive = appsettingsservice._isShowBannerActive;

   
}


ngOnAfterViewInit(){
 
}



ngOnInit() {
  this.isHandset$.subscribe(x=> console.log(x));


  this.router.events.subscribe((evt) => {
    if (!(evt instanceof NavigationEnd)) {
        return;
    }
    console.log("scrollitnow", evt);
    const contentContainer = document.querySelector('.mat-sidenav-content') || window;
         
    //contentContainer.scrollTo(0, 0);
    contentContainer.scrollTo({ left: 0, top: 0, behavior: 'smooth' });


    this.scrollService.scroll$.subscribe(pos => {
     
      console.log(pos)
      if (pos>200){
        this.appsettingsservice.setHeaderActive(false);
  
      
      
      }else{       
        // this.appsettingsservice.setHeaderActive(true);
      }
    })
/*

    var scrollToTop = window.setInterval(function () {

     

      
        var pos = window.pageYOffset;
        if (pos > 0) {
          //  window.scrollTo(0, pos - 20); // how far to scroll on each step

          //  document.body.scrollTop = 0;
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 16); // how fast to scroll (this equals roughly 60 fps)*/
});
}

}
