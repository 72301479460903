import { Component, OnInit , ChangeDetectorRef, NgZone  } from '@angular/core';
import { VgAPI } from 'videogular2/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HostListener } from '@angular/core'



@Component({
  selector: 'app-production',
  templateUrl: './production.component.html',
  styleUrls: ['./production.component.scss']
})
export class ProductionComponent implements OnInit {
  artworkUrl: String;
  waveformPng: String;
  title: String = "none";
  artist: String;
  genre:String;
  description:String;
  purchase_url:String;
  waveform_url:String;
  permalink_url:String;
  wave_width: string="0%";
  song:any;
  player: any;
  sounds:any;
  duration:number;
soundsinfo:any;
loaded:Boolean=false;
isPaused:Boolean=true;
element: HTMLElement;
scrub:any; seek:Number;
pOffset:any;

volume:number=100;

  constructor(private cdr: ChangeDetectorRef, private zone: NgZone) { }
  
  @HostListener('mousemove', ['$event'])
    onMousemove(event: MouseEvent) {

      let pwidth=event.srcElement.parentElement.offsetWidth;
      this.scrub = (event.clientX/pwidth);

        }
       
    //    @HostListener('click', ['$event'])
        onClick(event: MouseEvent) {
        
          let pwidth=event.srcElement.parentElement.offsetWidth;
          this.scrub = (event.clientX/pwidth);
          console.log(event.clientX,pwidth, this.player.duration);

          this.seek = parseFloat(this.scrub) * this.duration;

          console.log( this.seek);
  
          this.player.seekTo( this.seek);  
            //if(this.mouseDown) {
              /* this.scene.rotate(
                  event.clientX - this.last.clientX,
                  event.clientY - this.last.clientY
               );*/
              // this.last = event;
            }
        
            
   /* mouseMove(event) {
      event.preventDefault();
      this.scrub = (event.pageX-this.pOffset.left);
console.log(this.scrub);
   //  this.element.setAttribute('x', event.clientX + this.clientX + 'px');
   //   this.element.setAttribute('y', event.clientX + this.clientY + 'px');
    }*/
  

  openurl(myurl:string):void{
    console.log(myurl);
    window.open(myurl,"blank");
    this.player.pause();
  }
 
 
  //call after player init andbind
  setInfo(): void{
  let self=this;
    this.player.getCurrentSound(function(song) {
      self.song=song;
        console.log(self, self.song);
        self.title= song.title;
        self.description=song.description;
        self.genre=song.genre;
        self.purchase_url=song.purchase_url;
        self.waveform_url=song.waveform_url;
        self.permalink_url=song.permalink_url;
        self.duration=song.duration;
        // this.artist= song.artist;
        self.waveformPng =
          song.waveform_url
              .replace('json', 'png')
              .replace('wis', 'w1');
        
              self.artworkUrl = song.artwork_url || '';
           //   self.title="lol2";
           self.cdr.detectChanges();
     });

  }
  //call after player init andbind
  getPlaylist(): void{

    let self=this;
    this.player.getSounds(function(sounds) {
        console.log(sounds);
        if(sounds instanceof Array){
        self.soundsinfo=sounds[sounds.length];
        self.sounds=sounds.slice(0, sounds.length - 2);
        self.cdr.detectChanges();
      }
    });
  }


  checkPauseStatus(){}

  play(){
    let self=this;
    this.player.isPaused(function(e){
      console.log(e)
      if(e){
        self.player.play();
        self.isPaused=false;
      }else{
        self.player.pause();
        self.isPaused=true;
      }    

    });
  }

  pause(): void{  let self=this;  self.player.pause();}

  next(): void{
    let self=this;  self.player.next();    self.setInfo();    

  }

  previous(): void{
    let self=this;  self.player.prev();    self.setInfo();    

  }

  setVolume(event):void{
    let self=this;     console.log(self.volume);
    self.player.setVolume(self.volume);
  }

  playSong(song: any, index: number):void{
    console.log("playing",song);
    this.loaded=false;
    let self=this;
    self.loaded=false;
    
    self.player.skip(index);
    self.setInfo();    

    self.cdr.detectChanges();

    /*
    this.player.load(song.permalink_url, function(){
      self.setInfo();    
      console.log("loading");
      self.player.play();    
      self.loaded=false;

      self.cdr.detectChanges();

    });
*/
  }
  ngOnInit() {    

  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    //@ts-ignore
    this.player = SC.Widget('myscplayer');
 //this.pOffset = window.document.getElementsByClassName('.player').offset();
    // var pOffset = $('.player').offset();
   // var pWidth = $('.player').width();
   let self=this;
   //@ts-ignore
   this.player.bind(SC.Widget.Events.READY, function() {

       
   

   console.log("sc-player-ready");
   self.title="soundcloud";
   self.setInfo();
   self.getPlaylist();
   self.player.play();

   console.log(self.player);
  }); //Set info on load
  
 //@ts-ignore
 self.player.bind(SC.Widget.Events.PLAY_PROGRESS, function(e) {
  if(e.loadedProgress==1 && self.loaded==false){ 
    self.loaded=true;

    console.log("playprogress", e); self.setInfo();}
  /* if( e.relativePosition < 0.003 ) {   console.log("playprogress", e);
      ; self.setInfo(); }*/
    //Event listener when track is playing
    self.wave_width= e.relativePosition*100+"%"
    self.cdr.detectChanges();

  });

  //@ts-ignore
  self.player.bind(SC.Widget.Events.LOAD_PROGRESS, function(e) {
    console.log("loading_prog" ,e);
    self.setInfo(); 
    //Event listener when track is playing
  // $('.position').css('width', ( e.relativePosition*100)+"%");
  });

}


  

}
