
import {
    trigger, state,
    animate,
    transition,
    style,
    query
  } from '@angular/animations';
  
  export const fadeoutTopPanelButtonAnimation = trigger('fadeoutTopPanelButtonAnimation', [
    
    state('false', style({left: '10px' })),   
    
    state('true',  style({opacity: '1' })),
    
transition ('false <=> true', animate('300ms ease-in-out'))
  

  ]);


