import { Component, ViewChild  } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { fadeAnimation } from '../animations/fadeAnimation';
import { slideInOutAnimation } from '../animations/slideInOutAnimation';
import { slideAnimation } from '../animations/slideAnimation';
import { Router, NavigationStart } from '@angular/router';
import {MatSidenav}from '@angular/material'
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { AppsettingsService } from '../services/appsettings.service';
import { IAppData } from '../services/interfaces/iapp-data';

import { throttleTime,pairwise,distinctUntilChanged, share ,filter} from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { AfterViewInit } from '@angular/core';

import { WindowScrollService } from '../services/window-scroll.service';

enum Direction {
  Up = 'Up',
  Down = 'Down'
}

@Component({
  selector: 'app-navmain',
  templateUrl: './navmain.component.html',
  styleUrls: ['./navmain.component.scss'],
  animations: [fadeAnimation, slideInOutAnimation, slideAnimation] // register the animation
})
export class NavmainComponent implements AfterViewInit{
  

  @ViewChild(MatSidenav) sidenav: MatSidenav;
  //currentBannerUrl: Observable<SafeResourceUrl> ; 


  isShowBannerActive: BehaviorSubject<boolean>;
  appdata:IAppData = this.appsettingsservice.getAppsettings();

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver,  public appsettingsservice: AppsettingsService,

  router:Router, private scrollService: WindowScrollService) {
 //   this.currentBannerUrl = appsettingsservice.BannerURL;

    this.isShowBannerActive=appsettingsservice._isShowBannerActive;

    router.events.subscribe(event => {
      if(event instanceof NavigationStart) {
          this.sidenav.close();
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });

    console.log(appsettingsservice.BannerURL)
}



ngAfterViewInit() {
  const content = document.querySelector('.mat-sidenav-content');
  const scroll$ = fromEvent(content, 'scroll').pipe(
    throttleTime(10), // only emit every 10 ms
    map((scrollTop):number => content.scrollTop), // get vertical scroll position
  //  pairwise(), // look at this and the last emitted element
  //  map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)), // compare this and the last element to figure out scrolling direction
  //  distinctUntilChanged(), // only emit when scrolling direction changed
  //  share(), // share a single subscription to the underlying sequence in case of multiple subscribers
  );

  const $scrollTop= scroll$.pipe();

 // const goingUp$ = scroll$.pipe(
 //   filter(direction => direction === Direction.Up)
 // );

 // const goingDown$ = scroll$.pipe(
 //   filter(direction => direction === Direction.Down)
 // );
  $scrollTop.subscribe((res) => {
    //console.log(res)
  
    this.scrollService.scroll$.next(res);
  });

 // goingUp$.subscribe(() => console.log('scrolling up') );
 // goingDown$.subscribe(() => console.log('scrolling down') );
}
}
