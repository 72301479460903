import { Component, OnInit } from '@angular/core';
import { ResumeproviderService, IReferences } from '../services/resumeprovider.service';

@Component({
  selector: "app-references",
  templateUrl: "./references.component.html",
  styleUrls: ["./references.component.scss"]
})
export class ReferencesComponent implements OnInit {
  
  public references: Array<IReferences> 


  constructor(resumeproviderService: ResumeproviderService) {
      this.references= resumeproviderService.references;
  }


  ngOnInit() {}


  shouldInvertlogo(reference: IReferences): boolean {
    return reference.invertlogo;
  }
  shouldInvertlogoDarkTheme(reference: IReferences): boolean {
    return reference.invertlogodarktheme;
  }


  hideelem(reference) {

    if(reference.hidden===true){
      setTimeout(() => {
        reference.hidden = !reference.hidden;
      this.references.forEach(_reference => {
        if (reference != _reference) _reference.hidden = false;
      });
      }, 300);
     
       return;
    }

    reference.hidden = !reference.hidden;
    setTimeout(() => {
      this.references.forEach(_reference => {
        if (reference != _reference) _reference.hidden = false;
      }); 
    }, 300);
 
  }
}
