import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { map } from 'rxjs/operators';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { IImage } from '../interfaces/iimage'
import { ResumeproviderService, IWebsiteCards } from '../services/resumeprovider.service';

import { FullscreenpictureserviceService } from '../fullscreenpicture/fullscreenpictureservice.service';

@Component({
  selector: 'app-websites',
  templateUrl: './websites.component.html',
  styleUrls: ['./websites.component.scss'],
  animations: [
    trigger('flipState', [
      state('active', style({
       transform: 'rotateY(179.99deg)'
    // transform: 'translateX(100px)'
      })),
      state('inactive', style({
       transform: 'rotateY(0deg)'
      })),
      transition('active => inactive', animate('500ms ease-out')),
      transition('inactive => active', animate('500ms ease-in'))
    ])  
  ]
})
export class WebsitesComponent implements OnInit {

  websitecards: Array<IWebsiteCards> 

  columns:number=2;
  rowHeight:string="1:1";
  hidefooter:boolean = true;

  constructor(private breakpointObserver: BreakpointObserver,
      private cdRef:ChangeDetectorRef, private resumeproviderService: ResumeproviderService, private fullscreenpictureservice:FullscreenpictureserviceService) {
  
           this.websitecards= resumeproviderService.websitecards;

           this.websitecards.forEach(card => card.flip='inactive') //active/inactive
  
  }

  ngOnInit() {

    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {this.columns=6 ; }
    else if(this.breakpointObserver.isMatched(Breakpoints.XLarge)) {this.columns=8 ;  }

    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)){ this.columns=4 ;}
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {this.columns=2 ;}
    else if(this.breakpointObserver.isMatched(Breakpoints.Handset)) {this.columns=2 ; }
  }

  checkBreakpoint= function(result){
    if(result.breakpoints[Breakpoints.Large]) {this.columns=6 ;          /*this.rowHeight="240px"*/  }
    else if(result.breakpoints[Breakpoints.XLarge]) {this.columns=8 ;    /*this.rowHeight="240px"*/  }

    else if(result.breakpoints[Breakpoints.Medium]){ this.columns=4 ;    /*this.rowHeight="240px"*/  }
    else if(result.breakpoints[Breakpoints.Small]) {this.columns=2 ;     /*this.rowHeight="280px"*/  }
    else if(result.breakpoints[Breakpoints.Handset]) {this.columns=2 ;   /*this.rowHeight="200px"*/  }

  }

  /** Based on the screen size, switch from standard to one column per row */
  cards = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large,'(min-width: 300px)']).pipe(
    map(( result ) => {
  //    this.columns=6
    this.checkBreakpoint(result)

    //      else {this.columns=6; this.hidefooter=true}

if(result.breakpoints['(min-width: 300px)']){ this.hidefooter=true}else{ this.hidefooter=false}

      let _resu = this.websitecards;
      console.log(result);

_resu.forEach(element => {
 // element.cols=1;          element.rows=1

});
  return _resu;
    })
  );


  toggleFlip(card: any) {   
  //  console.log("pre",card.flip);

   card.flip = (card.flip == 'inactive') ? 'active' : 'inactive';
   //this.cdRef.detectChanges();  
  //  console.log("post",card.flip);

 }

testev(event){
  console.log("wsdd",event);
}
 showPicture(url){
        this.fullscreenpictureservice.showPicture(url);
 }

 showFullScreenSlideshow(card){
  console.log("showFullScreenSlideshow")
    this.fullscreenpictureservice.showPictures(card.images)


 }


 
 test(index){console.log("index")}
 




}
