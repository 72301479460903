import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { map } from 'rxjs/operators';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { IImage } from '../interfaces/iimage';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { AppsettingsService } from '../services/appsettings.service';
import { IAppData } from 'src/app/services/interfaces/iapp-data';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResumeproviderService, IWerbebanner } from '../services/resumeprovider.service';


@Component({
  selector: 'app-werbebanner',
  templateUrl: './werbebanner.component.html',
  styleUrls: ['./werbebanner.component.scss'],
  animations:  [
    trigger('flipState', [
      state('active', style({
        transform: 'rotateY(179.9deg)'
      })),
      state('inactive', style({
        transform: 'rotateY(0)'
      })),
      transition('active => inactive', animate('500ms ease-out')),
      transition('inactive => active', animate('500ms ease-in'))
    ])  
  ]
})

export class WerbebannerComponent implements OnInit,OnDestroy {

  rowheight:string= "1:1.05";
   isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches)
  );
    
    currentBannerUrl: SafeResourceUrl; 
    gridColumns:number=1
    gridColumnsShBan:number=1

    werbebannercards: Array<IWerbebanner> 
   
    constructor(private breakpointObserver: BreakpointObserver,public sanitizer:DomSanitizer, private  appsettingsservice: AppsettingsService,
      private cdRef:ChangeDetectorRef, private resumeproviderservice: ResumeproviderService) {
    
        this.gridColumns=4;  this.gridColumnsShBan=4-this.showBan; 
         this.werbebannercards = resumeproviderservice.werbebannercards.sort((a, b) => parseInt(b.year+"") - parseInt(a.year+""));;
      }
      
  ngOnInit() {
    this.appsettingsservice._isShowBannerActive.subscribe(result=>{
      result==false? this.rowheight="1:1.05": this.rowheight="1:1.35";
    })
  }

  ngOnDestroy(): void {
    this.appsettingsservice._isShowBannerActive.next(false);
  }


  
  cards = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Small, '(min-width: 1600px)','(min-width: 1200px)','(min-width: 800px)']).pipe(
    map(( result ) => {
      let _resu = this.werbebannercards;
      console.log(result,  result.breakpoints['(min-width: 1200px)']);
      if(result.breakpoints['(min-width: 1600px)']){ this.gridColumns=4-this.showBan ;}
      else if(result.breakpoints['(min-width: 1200px)']){ this.gridColumns=3-this.showBan ;}
      else if(result.breakpoints['(min-width: 800px)'] ){this.gridColumns=2-this.showBan ;}
      else if(result.breakpoints[Breakpoints.Handset]){ this.gridColumns=1 ;}
      else if(result.breakpoints[Breakpoints.Small]){ this.gridColumns=1 ;}

      else {this.gridColumns=1}
      
      this.gridColumnsShBan=this.gridColumns;
      //if(this.isHandset$){this.gridColumns==Math.max(this.gridColumns-1,1)}
      //if((this.appsettingsservice._isShowBannerActive.value) ==true){this.gridColumns--}

      return _resu;
    })
  );

 showBan:number=0;
/*
 isShowActiv= this.appsettingsservice._isShowBannerActive.subscribe(value=>{
      value?this.showBan=1:this.showBan=0;
      this.gridColumnsShBan=this.gridColumns-this.showBan;

 })
*/
  toggleFlip(card: any) {   
    console.log("pre",card.url);

   card.flip = (card.flip == 'inactive') ? 'active' : 'inactive';
   this.cdRef.detectChanges();  
    console.log("post",card.flip);

 }

 showBanner(card: IWerbebanner) {   
  console.log("pre",card.link , this.appsettingsservice.BannerURL);

  if(card.link==this.appsettingsservice.BannerURL.value && this.appsettingsservice._isShowBannerActive.value==true) {  this.appsettingsservice.setShowBanner(false);}
  else if(card.link!=this.appsettingsservice.BannerURL.value && this.appsettingsservice._isShowBannerActive.value==false) {  this.appsettingsservice.setShowBanner(true);}
  else if(card.link==this.appsettingsservice.BannerURL.value && this.appsettingsservice._isShowBannerActive.value==false) {  this.appsettingsservice.setShowBanner(true);}

  //if (card.BannerURL!=this.currentBannerUrl && this.appsettingsservice._isShowBannerActive.value==false){ this.appsettingsservice.setShowBanner(true)}


  this.appsettingsservice.setBannerUrl( card.link);
   this.cdRef.detectChanges();  

  this.gridColumnsShBan=Math.max(this.gridColumns-this.showBan,1)

  console.log("post",card.link);

}

}
