import { Component, OnInit } from '@angular/core';
import { ResumeproviderService, IClubevents } from 'src/app/services/resumeprovider.service';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject, Observable } from 'rxjs';
import {map} from 'rxjs/operators'

@Component({
  selector: 'app-clubevents',
  templateUrl: './clubevents.component.html',
  styleUrls: ['./clubevents.component.scss']
})
export class ClubeventsComponent implements OnInit {
cols:BehaviorSubject<number> = new BehaviorSubject(1);
  clubevents: Array<IClubevents>
  constructor(resumeproviderService:ResumeproviderService ,
                private breakpointObserver: BreakpointObserver) {
      this.clubevents= resumeproviderService.clubevents;

      let subscription = this.breakpointObserver.observe(Breakpoints.Handset)
      .subscribe((result)=>{
        console.log(result);
          if(result.matches){this.cols.next(2);} else {this.cols.next(1);}
    
      });
          
           
    
  }

  ngOnInit() {
  }


  
  

}
