import { Component, OnInit } from '@angular/core';
import {RatingModule} from "ngx-rating";

import { ResumeproviderService, IHobbies } from 'src/app/services/resumeprovider.service';

@Component({
  selector: 'app-hobbies',
  templateUrl: './hobbies.component.html',
  styleUrls: ['./hobbies.component.scss'],
 
})
export class HobbiesComponent implements OnInit {
  hobbies: Array<IHobbies>
  hobbies_english: Array<IHobbies>

  constructor(resumeproviderservice: ResumeproviderService) {
      this.hobbies_english= resumeproviderservice.hobbies_english;
      this.hobbies=  resumeproviderservice.hobbies;  
 }


  ngOnInit() {
  }


}
