import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { IAppData } from './interfaces/iapp-data';

@Injectable({
  providedIn: 'root'
})
export class AppsettingsService {


  defaultdarkmode:boolean=true;
defaultHeaderActive:boolean=true;
defaultBanner:SafeResourceUrl="./assets/werbebanner/2021_Stickermania/500x1000XLr/index.html"; 
defaultShowBanner:boolean=false;

  constructor() { }
  
  
  appdata:IAppData = {
    title: 'Georg Lindner Turecka',
    headerimage_urls:[
      //"/assets/topbanner/comradednb_18NOV2017_fullres_22of30.JPG", 
                        "/assets/topbanner/georg_2023_sbg.jpg", 
                 //     "/assets/topbanner/serag-66.jpg", 
                      //"/assets/topbanner/serag-72.jpg",
                      "/assets/topbanner/georgclaus2018donauinselfest.jpg",
                      "/assets/topbanner/20160830_OpticalEngineers_SC_0412_web.jpg" ,
                     
               //       "/assets/topbanner/img_5307_16_cropped.jpg" 
                     ],




    //darkmode:true
  }

  private _darkTheme: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.defaultdarkmode);
  isDarkTheme = this._darkTheme.asObservable();

  setDarkTheme(isDarkTheme: boolean) {
    this._darkTheme.next(isDarkTheme);
  }

  public _isHeaderActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.defaultHeaderActive);
  isHeaderActive = this._isHeaderActive.asObservable();

  setHeaderActive(setisHeaderActive: boolean) {
    this._isHeaderActive.next(setisHeaderActive);
  }

  toggleHeaderActive() {
    this._isHeaderActive.next(!this._isHeaderActive.getValue());

  }
  public _isShowBannerActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.defaultShowBanner);

  setShowBanner(IsShowBannerActive: boolean) {
    this._isShowBannerActive.next(IsShowBannerActive);
  }
  isShowBanner = this._isShowBannerActive.asObservable();
  toggleShowBannerActive() {
    this._isShowBannerActive.next(!this._isShowBannerActive.getValue());

  }

  private _BannerUrl: BehaviorSubject<SafeResourceUrl> = new BehaviorSubject<SafeResourceUrl>(this.defaultBanner);

  setBannerUrl(setBanner_Url: SafeResourceUrl) {
    this._BannerUrl.next(setBanner_Url);
  }
  BannerURL = this._BannerUrl;


  public getAppsettings() {
    return this.appdata;
}

}