import { Pipe, PipeTransform } from '@angular/core';
import { ISkills } from '../services/resumeprovider.service';

@Pipe({
  name: 'selectpipe'
})
export class SelectpipePipe implements PipeTransform {

    transform(array: Array<ISkills>, args?: any): Array<ISkills> {
        let result: Array<ISkills>=[];
        console.log(args);

        if(args.property=="all"){return array;}

        array.forEach(element => {
          let isElMatched: Boolean =false;


               element.category.forEach(category => {
                    if(category==args.property){isElMatched=true;}
                    console.log(element);
               })


               if(isElMatched==true){result.push(element);}
        });

return result;

     /* return array.sort(function(a, b){
        if(a[args.property] < b[args.property]){
            return -1 * args.order;
        }
        else if( a[args.property] > b[args.property]){
            return 1 * args.order;
        }
        else{
            return 0;
        }
      });
    */
    }  

}
