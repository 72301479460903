import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { fadeAnimation } from '../animations/fadeAnimation';
import { slideInOutAnimation } from '../animations/slideInOutAnimation';
import { slideAnimation } from '../animations/slideAnimation';



@Component({
  selector: 'app-music',
  templateUrl: './music.component.html',
  styleUrls: ['./music.component.scss'],
  animations: [fadeAnimation, slideInOutAnimation, slideAnimation] // register the animation

})

export class MusicComponent implements OnInit {
  enableediting: false;

  ngOnInit() {
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

constructor(private breakpointObserver: BreakpointObserver) {}

 
}
