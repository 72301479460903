import { Pipe, PipeTransform } from '@angular/core';
import { ISkills } from '../services/resumeprovider.service';

@Pipe({
  name: 'iskillslanguagepipe'
})
export class IskillslanguagepipePipe implements PipeTransform {

    transform(array: Array<ISkills>, args?: any): Array<ISkills> {
      let result: Array<ISkills>=[];
      console.log(args);

      if(args.property=="all"){return array;}

      array.forEach(element => {
        let isElMatched: Boolean =false;

          if(args.property=="Diverse"){   
            isElMatched=true;   


              ["C#","PHP","Javascript","C++","PHP","Python","JAVA"].forEach( element2 =>{

                if (element2==element.language) {isElMatched=false;}
              });

          }else{
              element.language==args.property? isElMatched=true: isElMatched=false;
          }

          if(isElMatched==true){result.push(element);}
      });

return result;  }

}
