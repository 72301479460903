import { Injectable } from '@angular/core';
import { SafeResourceUrl} from '@angular/platform-browser';
import { IImage } from '../interfaces/iimage';

export  interface IReferences {
  company: string,
  invertlogo:boolean,invertlogodarktheme:boolean,
hidden:boolean,
year: string,
start: string,
end: string,
mainocc: string,
description: string,
description2?: string,
link:string,
imgavatar: string,
occupation: Array<any>
}

export interface IWerbebanner {
  title:String,
  subtitle: String
 link:SafeResourceUrl,
 img:String, 
 imgavatar:String,

 images:Array<IImage>,
 currentSlide:number,

 description:String,
 year: String,
 usedTech: Array<String>,
 cols: number, rows: number, flip: string
}

export interface IWebsiteCards {
  title:String,
  subtitle: String
 link:String,
 img:String, 
 imgavatar:String,

 images:Array<IImage>,
 currentSlide:number,

 description:String,
 year: String,
 usedTech: Array<String>,
 cols: number, rows: number, flip: string
}
export interface ISoftwareData{
  title:string,
  subtitle:string,
  link:string,
  img:string,  img2:string,
images:Array<IImage>,
currentSlide:number,
description:string,
  year:string,
  usedTech: Array<string> 
  cols: number, rows: number, flip: string
}

export interface ISkills {

   order:number,
   topic:string,
    level:number,
    maincat:string,
    category:Array<string>     ,            
    language:string,         
}
export interface IHobbies{
  name: String,
  description: String,
  comment: String,
  passion: number,
  open: boolean,

}
export interface ISoftskill{

  name: string,
  descriptions: Array<ISoftskillDesc>
}

export interface ISoftskillDesc{
  description:string,
}

export interface IClubevents {
  name :                      string,
  description :            string,
  occupation:             string,
  locations:               Array<string>,
  avg_visitors_per_party: number,
  founded:                Date,
  ended:                  Date,
  frequency:               string,
  img_main_url:              string,
  img_logo_url:           string,
  link:                   string,
  occupations:              Array<string>
}

@Injectable({
  providedIn: 'root'
})
export class ResumeproviderService {


 

  softskills: Array<ISoftskill> = [
    {name: "Teamfähigkeit" , descriptions: [{description:"Die erfolgreiche Organisation von Veranstaltungen und IT-Projekten erfordert ein starkes Team. Mit meinen IT-Fähigkeiten und Teamwork-Erfahrungen bin ich bereit, zu Ihrem Erfolg beizutragen."}]},
    {name: "Kompromissbereitschaft & Flexibilität" , descriptions: [{ description:"Im Musikwerbebereich sind Kundenmeinungen oft subjektiv geprägt. Mein Ansatz besteht darin, genau zuzuhören und kreativ darauf einzugehen, um maßgeschneiderte Lösungen zu liefern, die den Kundenwünschen entsprechen und den Erfolg der Kampagne sicherstellen."}]},
    {name: "Analytisches Denkvermögen", descriptions: [{ description:"Projektplanung beginnt mit dem Zerlegen komplexer Probleme in überschaubare Aufgaben. Diese strategische Herangehensweise ermöglicht es, die Gesamtvision auf effiziente Weise umzusetzen und erfolgreich Projekte zu realisieren."}]},
    {name: "Hohes Qualitätsbewusstsein", 
    descriptions: [{ description:"In einem Serviceumfeld sind Ausfallzeiten inakzeptabel. Eine solide Vorbereitung durch automatisierte Tests und effektives Krisenmanagement sind entscheidend, um potenzielle Probleme proaktiv zu verhindern und einen reibungslosen Betrieb sicherzustellen."}]},
    {name: "Ergebnisorientiert", 
    descriptions: [{description: "Beschreibung: In der Kreativ als auch in der IT-Branche ist die Konzentration auf das Endresultat von entscheidender Bedeutung. Dies erfordert die Fähigkeit, sich schnell von unproduktiven Ansätzen zu verabschieden und stattdessen zielgerichtet auf erfolgreiche Lösungen hinzuarbeiten."}]},
    {name: "Lernbereitschaft & Eigeninitiative", 
    descriptions: [{ description: "Moderne Technologien verbessern die Arbeitseffizienz und sparen Zeit. Die anfängliche Lerninvestition zahlt sich langfristig aus, da sie Engpässe reduziert und strategische Zeit freisetzt. Ich bringe die Bereitschaft mit, stets aktuelle IT-Kenntnisse zu erwerben."}]},
  
  ]



  public biography={
    img_portrait_url:"/assets/dashboard/biography/georgturecka_Avatar2022.jpg",
    firstname: "Georg",
    lastname: "Lindner Turecka",
    birthtown: "Wien",
    //birthyear:"1982"
    residency: "Wien",
    occupation: "Media Informatics",
    studies:[
      "HTL Wien X, Nachrichtentechnik", "Technischen Universität Wien Medieninformatik"],
    interrests:["Software Development", "Webdesign", "Audio Production", "Machine Learning"]
  }

  public hobbies_english=  [
    { name: "Music"              , description:"more than a hobby", comment: "It was more than a hobby very soon!", passion: 5          , open:false },
    { name: "Cycling"            , description:"is freedom",comment: "It's the better alternative to running", passion: 4        , open:false },

    { name: "Walking"            , description:"in Vienna",comment: "Walking home instead of using public Transport", passion: 5, open:false },
    { name: "RC Flying"          , description:"when i was 16",comment: "RC Soaring and Electro Glider's", passion: 2               , open:false  },
    { name: "MechwarriorOnline" , description:"100t firepower",comment: "Tactical Team Slow Shooter", passion: 4                    , open:false },
    { name: "Starcraft",           description:"Livestreams", comment: "Watching Starcraft1/2 Pro-Games", passion: 3              , open:false},
    { name: "Minecraft",           description:"Sandbox/Survival", comment: "Builds and Farms on a self hosted Minecraft Server", passion: 3              , open:false},

  ]
 


  public hobbies=  [
  { name: "Musik"              , description:"vom Hobby zum Beruf", comment: "Kreativität und Rythmus als Ausgleich zur Technik.", passion: 5          , open:false },
  { name: "Radfahren"            , description:"Transportmittel",comment: "Besser als laufen und gut um Freunde und Sport zu kombinieren", passion: 4        , open:false },

  { name: "Wandern"            , description:"Stadt, Land und über den Fluss",comment: "Ob im Wald oder nachts quer durch Wien", passion: 5, open:false },
  { name: "Modellfliegen"          , description:"Jugendhobby",comment: "Hangsegeln und Elektrosegler", passion: 2               , open:false  },
  { name: "Mechwarrior"   , description:" Tactical Team Slow Shooter",comment: "12 Spieler Free for All mit Teamspeak/Discord", passion: 4                    , open:false },
  { name: "Starcraft",           description:"Echtzeitstrategie", comment: "Liveübertragungen von Pro-Games", passion: 3              , open:false},
  { name: "Minecraft",           description:"Sandbox/Survival", comment: "Bauen von Farmen und schönen Builds am eigenen Minecraft Server", passion: 3              , open:false},

];



  public skills: Array<ISkills>=[
    { order:0,    topic: "C#",                     level:90,  maincat:"Programming"  ,category:["Programming"  , "Web", "App"                            ]                             , language:"C#"               },
    { order:1,    topic: ".NET",                   level:80,  maincat:"Framework"    ,category:["Framework"    , "Web", "App"                            ]                             , language:"C#"               },
    { order:2,    topic: "WPF",                    level:90,  maincat:"UI-Framework" ,category:["Frontend"  , "App"                                  ]                             , language:"C#"               },
    { order:3,    topic: "Winforms",               level:60,  maincat:"Programming"  ,category:["Frontend"  , "App"                                   ]                             , language:"C#"               },
    { order:4,    topic: "BASS.NET",               level:90,  maincat:"Programming"  ,category:["Programming"  ,"Audio" ,"Realtime"                      ]                             , language:"C#"               },
    { order:5,    topic: "HTML5+CSS3",             level:100,  maincat:"Web"          ,category:["Web"          ,"Programming"                            ]                             , language:"Javascript"            },
    { order:6,    topic: "Javascript",             level:100, maincat:"Programming"  ,category:["Programming"  ,"Web"                                    ]                             , language:"Javascript"       },
    { order:7,    topic: "Typescript",             level:90,  maincat:"Programming"  ,category:["Programming"  ,"Web","Javascript"                       ]                             , language:"Javascript"       },
    { order:8,    topic: "Angular1",               level:90,  maincat:"Framework"    ,category:["Frontend"    ,"Javascript", "Web", "App"               ]                             , language:"Javascript"       },    
    { order:9,    topic: "Angular2",               level:80,  maincat:"Framework"    ,category:["Frontend"    ,"Javascript", "Web", "App"               ]                             , language:"Javascript"       },
    { order:10,   topic: "Angular1 Material",      level:90,  maincat:"UI-Framework" ,category:["UI-Framework", "App", "Web","Javascript" , "Angular1"   ]                             , language:"C#"               },
    { order:11,   topic: "Angular2 Material",      level:80,  maincat:"UI-Framework" ,category:["UI-Framework", "App", "Web","Javascript" , "Angular2"   ]                             , language:"C#"               },
    { order:12,   topic: "Ionic",                  level:80,  maincat:"App-Framework",category:["App-Framework", "App", "Web","Javascript", "Angular1"   ]                             , language:"C#"               },
    { order:13,   topic: "NodeJS",                 level:90, maincat:"Framework"    ,category:["Framework"    ,"Javascript", "Web", "App"               ]                             , language:"Javascript"       },
    { order:14,   topic: "NPM",                    level:70,  maincat:"Framework"    ,category:["Framework"    ,"Javascript", "Web", "App"               ]                             , language:"Javascript"       },
    { order:15,   topic: "GULP",                   level:70,  maincat:"Framework"    ,category:["Framework" , "Frontend"   ,"Javascript", "Web", "App"     ]                             , language:"Javascript"       },
    { order:16,   topic: "BOWER",                  level:70,  maincat:"Framework"    ,category:["Framework"    ,"Javascript", "Web", "App"               ]                             , language:"Javascript"       },
    { order:17,   topic: "ANGULAR CLI",            level:80,  maincat:"CLI"          ,category:["CLI"          ,"Javascript", "Angular1", "Angular2+"    ]                             , language:"Javascript"       },
    { order:18,   topic: "Express",                level:90,  maincat:"Framework"    ,category:["Framework"    ,"Javascript", "Web", "App"               ]                             , language:"Javascript"       },
    { order:19,   topic: "Phaser.IO",              level:80,  maincat:"Framework"    ,category:["Frontend"    ,"Javascript", "Web", "App"               ]                             , language:"Javascript"       },
    { order:20,   topic: "Jasmine",                level:70,  maincat:"TestFramework",category:["TestFramework","Javascript", "Web", "App"               ]                             , language:"Javascript"       },
    { order:21,   topic: "C++",                    level:60,  maincat:"Programming"  ,category:["Programming"                                            ]                             , language:"C++"              },
    { order:22,   topic: "VST Framework",          level:90,  maincat:"Framework"    ,category:["Frontend"    ,"Audio" ,"Realtime"                      ]                             , language:"C++"              },
    { order:23,   topic: "VSTGUI",                 level:90,  maincat:"Framework"    ,category:["Framework"    ,"Audio" ,"Realtime"                      ]                             , language:"C++"              },
    { order:24,   topic: "JAVA",                   level:60,  maincat:"Programming"  ,category:["Programming"  ,"App"                                    ]                             , language:"JAVA"             },
    { order:25,   topic: "MAVEN",                  level:50,  maincat:"TaskRunner"   ,category:["TaskRunner"                                             ]                             , language:"JAVA"             },
    { order:26,   topic: "Spring",                 level:50,  maincat:"Injection"    ,category:["Injection"    ,"Programming"                            ]                             , language:"JAVA"             },
    { order:27,   topic: "Amiga Basic",            level:80,  maincat:"Vintage"      ,category:["Vintage"      ,"Programming"                            ]                             , language:"Amiga Basic"      },
    { order:28,   topic: "Assembler",              level:90,  maincat:"Vintage"      ,category:["Vintage"      ,"Programming"                            ]                             , language:"Assembler"        },
    { order:29,   topic: "Mathematica",            level:70,  maincat:"MAthematics"  ,category:["Mathematics"                                            ]                             , language:"Mathematica"      },
    { order:30,   topic: "Viewdraw",               level:60,  maincat:"Electronics"  ,category:["Electronics"                                            ]                             , language:"Viewdraw"         },
    { order:31,   topic: "Bitwig",                 level:100, maincat:"Audio"        ,category:["Audio"        ,"JAVA","Realtime"                        ]                             , language:"JAVA"             },
    { order:32,   topic: "Open Stage Control OSC", level:80,  maincat:"Audio"        ,category:["Audio"        ,"JAVA" ,"Realtime"                       ]                             , language:"JAVA"             },
    { order:33,   topic: "PHP",                    level:100, maincat:"Web"          ,category:["Web"                                                    ]                             , language:"JAVA"             },
    { order:34,   topic: "Wordpress",              level:90,  maincat:"Web"          ,category:["Web" ,"PHP"                                             ]                             , language:"PHP"              },
    { order:35,   topic: "WP Themes",              level:50,  maincat:"Web"          ,category:["Web" ,"PHP"                                             ]                             , language:"PHP"              },
    { order:36,   topic: "Minecraft",              level:80,  maincat:"Server"          ,category:["Server" ,"Web",                                             ]                      , language:"JAVA"              },
    { order:37,   topic: "StableDiffusion",        level:30,  maincat:"Programming"          ,category:["Programming" ,"Art",    "Audio"                 ]                      , language:"JAVA"              },
    { order:38,   topic: "Python",                 level:30,  maincat:"Programming"          ,category:["Programming" ,  "Web", "ML"              ]                      , language:"JAVA"              },
    { order:38,   topic: "GSAP Animation Lib",     level:90,  maincat:"Web"          ,category:["Programming" ,  "Web", "Javascript"              ]                      , language:"Javascript"              },

  ]

  public references: Array<IReferences> = [
    {
      company: "HTL Nachrichtentechnik",
      invertlogo:false,invertlogodarktheme:false,

      hidden: false,
      year: "1996",
      start: "1996",
      end: "2001",
      mainocc: "Student",
      description: "HTL WIEN 10 Ettenreichgasse Nachrichtentechnik",
      description2: "Elektronik, Borland C++ und Assemblerprogrammierung am 80c51, Maturaprojekt: Mikroprozessor Entwicklung mit Viewdraw und integration in einen Xylinx FPGA",
      link: "https://www.htlwien10.at/edu/",
      imgavatar:"/assets/logos/cropped-HTL_Wien10_Logo_Web.png", 
      occupation: [
        
        "Assembler",
        "C++",
        "Viewdraw",
        "FPGA",
        "Mikrocontroller"
      ]
    },
    {
      company: "Siemens PSE KB",
      invertlogo:false,invertlogodarktheme:false,

      hidden: false,
      year: "1998",
      start: "1998",
      end: "1998",
      description: "Siemens Programm und Systementwicklung",
      description2: "Fernsteuern von Excel mittel SVScript in WSH, Automatisiertes parsen und drucken von HTML-Manuals per Windows Scripting Host in einer Zeit vor dem LOVELETTER Wurm",
      mainocc:"Pflichtpraktikum HTL",
      link:"https://www.psenterprise.com/",
      imgavatar:"/assets/logos/Siemens-logo.svg", 

      occupation: [ "Windows Scripting Host", "HTML", "ActiveX"]
    },
    {
      company: "Canon Österreich",
      invertlogo:false,invertlogodarktheme:false,

      hidden: false,
      year: "1999",
      start: "1999",
      end: "2000",
      mainocc:"Pflichtpraktikum HTL",
      
      description: "Canon Österreich",
      description2: "Update Service für Kopierer und Drucker, per Eproms oder CD. Kopieren und Verschicken der Eproms, Kopieren von Treiber und Firmware CD's mittels Kleinserienroboters mit 4 CD Brennern",
      link:"https://www.canon.at/",
      imgavatar:"/assets/logos/Canon-Logo.png", 

      occupation: ["Ferialpraxis", "Eprom Software Update", "Service", "CD Brenn Roboter"]
    },
    {
      company: "Civil Service CARITAS",
      invertlogo:true,invertlogodarktheme:true,
      hidden: false,
      year: "2002",
      start: "2002",
      end: "2003",
      mainocc:"Civil Zivildienst",

      description: "CARITAS Retirement Home",
      description2: "Altenpflege und Betreuung im 14. Wiener Gemeindebezirk.",
      link: "https://www.caritas-pflege.at/haus-st-klemens",
      imgavatar:"/assets/logos/Caritas_Austria-logo.svg", 

      occupation: ["cleaning", "talking", "helping", "starving"]
    },
    {
      company: "Helios Ventillatoren",
      invertlogo:false,invertlogodarktheme:false,

      year: "2003",
      hidden: false,
      start: "2003",
      end: "2003",
      mainocc:"IT-Unterstützung für Büroanwendungen, Büroangestellter",

      description: "Lüftung und Ventillatoren",
      description2: "Netzwerktechnik, Netzwerkbetreuung Workgroup Windows, Excel Berichterstellung, Excel Macro's und Scripte, Lagerbetrueung und Versand, Angebotserstellung für Grossbaustellen, Lieferungen von Kleinbestellungen rund um Wien",
     link: "https://www.heliosventilatoren.at/",
     imgavatar:"/assets/logos/helios_logo_logo_logo.png", 
     occupation: ["IT-Support", "Angebotslegung", "Lieferungen", "Lager"]
    },
    {
      company: "Technical University Vienna Studium Medieninformatik",
      invertlogo:false,invertlogodarktheme:false,

      hidden: false,
      year: "2003",
      start: "2003",
      end: "2008",
      mainocc: "Student",
      description: "Technische Universität Wien, Medieninformatik",
      description2: "Erstellung einer Web-Applikation mit JAVA, SQL und JAVA-UI, Erstellung interaktiver Games in Javascript, Projektmanagement",  
      link:"https://www.tuwien.at/studium/studienangebot/bachelorstudien/informatik-und-wirtschaftsinformatik",
      imgavatar:"/assets/logos/tu-uni-wien-logo.jpg", 

      occupation: ["JAVA", "SQL", "MAVEN", "GIT", "LINUX"]
    },

    {
      company: "CANON EAST EUROPE",
      invertlogo:false,invertlogodarktheme:false,

      hidden: false,
      year: "2004",
      start: "2004",
      end: "2014",
      mainocc:"IT-Unterstützung für Büroanwendungen, Exportsachbearbeiter",

      description: "IT-Unterstützung für Büroanwendungen, Exports Manager, CEE CANON EAST EUROPE",
      description2: "Infopath Formulare, MS Access Datenanbindung, Webshop-administration, Batchverarbeitung, Datenaufbereitung und Analyse, Excel Makro's, Scripte und Berichterstellung",
      link:"https://www.canon-cee.com/",
      imgavatar:"/assets/logos/Canon-Logo.png", 

      occupation: [        
        "PHP",
        "MS Infopath",
        "MS Access",
        "Oracle",
        "Sys90",
        "MS Excel mit ODB"
      ]
    },
    {
      company: "Sound-Sets.com",
      invertlogo:false,invertlogodarktheme:false,


      hidden: false,
      year: "2015",
      start: "2015",
      end: "2020",
      mainocc:"Developer/Admin FullStack",
      description: "Sound-Sets Music Solutions",
      description2: "Hintergrundmusik, Sounddesign and Stimmung for Hotels, Gastronomie und Geschäftslokale. Verwaltung und Fernwartung des Linux/Debian Firmenservers, Design der SoundplayerApp und dem zugehörigen Backend. Installation von Geräten beim Kunden sowie deren Fernwartung. Excel Makro's, Scripte und Berichterstellung",
      link:"sound-sets.com",
      imgavatar:"/assets/logos/soundsets_Metallic-Centercenter500x500.png", 
      occupation: [
        "Linux",
        "HTML",
        "PHP",
        "NodeJS",
        "Angular1/2",
        "C#",
        "Icecast"
      ]
    },
    {
      company: "Selbstständiger Web-Developer",
      invertlogo:false,invertlogodarktheme:false,

      hidden: false,
      year: "2017",
      start: "2017",
      end: "now",
      mainocc:"Server-Betreung/Debian/WordPress",
      description: "Selbsständig",
      description2: "Services in der Elektronischen Datenverarbeitung. HTML5 Banner nach IAB, Webshop-Erstellung, Wordpress-Hosting für selbsständige und kleine Betriebe, Sound-Design, Musik-Produktion, Audo-Aufbereitung, Live-Auftritte",
      link:"/",
      imgavatar:"/assets/websites/server_2.jpg", 

      occupation: [
        "Linux Server Betreung",
        "Wordpress Installation und Umzug",
        "Wordpress Reparatur",
        "Wordpress Plugin Development",
        "Woocommerce",
        "Wordpress Multisite",
        "Wordpress Page Design",    
        "IONIC App Anbindung an Wordpress Feed via REST API",
        "Musik",
        "Sound-Design",
        "Audio-Aufbereitung"
      ]
    },
    {
      company: "Page-84",
      invertlogo:false,      invertlogodarktheme:true,

      hidden: false,
      year: "2019",
      start: "2019",
      end: "now",
      mainocc:"Developer/MotionDesign",
      description: "Page-84 Werbeagentur",
      description2: "HTML5 Werbebanner nach IAB Standard, HTML5 Minigames, Grafikbearbeitung, Retouchieren von Objekten, Facebook/Instagram Filter und Augmented Reality mittels SPARK AR",
     link:"https://www.page-84.com/en/",
     imgavatar:"/assets/logos/page84.svg", 

      occupation: [
        "HTML5",
        "Javascript",
        "CSS",
        "SCSS",
        "Gulp Taskrunner",
        "SPARK AR",
        "GSAP Animation Library",
        "Adobe Animate",
        "Adobe Photoshop",
        "Adobe Indesign",
        "Adobe Illustrator",
        "Gimp"
      ]
    }
  ];



  public websitecards: Array<IWebsiteCards> = [
           
   
    { title: 'vitalundschoen.com',      
    subtitle: 'Wordpress',
     link:"https://www.vitalundschoen.com", 
     img:"/assets/websites/vitalundschoen_1.jpg", 
     imgavatar:"/assets/websites/vitalundschoen_avatar.png", 

     images:[
      {url: "/assets/websites/vitalundschoen_1.jpg", caption:""},

      {url: "/assets/websites/vitalundschoen_6.jpg", caption:""},
     {url: "/assets/websites/vitalundschoen_2.jpg", caption:""},
     {url: "/assets/websites/vitalundschoen_3.jpg", caption:""},
     {url: "/assets/websites/vitalundschoen_4.jpg", caption:""},
     {url: "/assets/websites/vitalundschoen_5.jpg", caption:""}],
      flip:'inactive',  
currentSlide:0,
     description:"Webshop für Vitalundschoen Naturkosmetik, Webshop mittels Woocommerce", 
     usedTech: ["Wordpress-CMS", "Woocommerce"],
     year: "2017",
     cols: 2, rows: 2 }
      
     ,

     { title: 'ginadrewes.com',   
        subtitle: 'Wordpress',
    
     link:"https://www.ginadrewes.com", 
     img:"/assets/websites/ginadrewes_1.jpg", 
     imgavatar:"/assets/websites/ginadrewes_avatar.png", 

     images:[
      {url: "/assets/websites/ginadrewes_1.jpg", caption:""},

      {url: "/assets/websites/ginadrewes_2.jpg", caption:""},
     {url: "/assets/websites/ginadrewes_3.jpg", caption:""},
     {url: "/assets/websites/ginadrewes_4.jpg", caption:""},
     {url: "/assets/websites/ginadrewes_5.jpg", caption:""}],
      flip:'inactive',  
currentSlide:0,
     description:"Webshop für die Modedesignerin Gina Drewes. Diese Seite bildet zusammen mit Monkeyonmyshoulder eine Multisiteinstallation mit gekoppeltem Rechnungs-system.<br> Man kann Bestellungen von mehreren Unter-Seiten zusammen abrechnen", 
     usedTech: ["Wordpress-CMS", "Wordpress-Multi-Site", "Woocommerce", "Multi-Site-Checkout"],

     year: "2017",
     cols: 2, rows: 2 }

     ,

     { title: 'monkeyonmyshoulder.com',   
        subtitle: 'Wordpress',
    
     link:"https://www.monkeyonmyshoulder.com", 
     img:"/assets/websites/monkeyonmyshoulder_1.jpg", 
     imgavatar:"/assets/websites/monkeyonmyshoulder_avatar.png", 

     images:[
      {url: "/assets/websites/monkeyonmyshoulder_1.jpg", caption:""},

      {url: "/assets/websites/monkeyonmyshoulder_2.jpg", caption:""},
     {url: "/assets/websites/monkeyonmyshoulder_3.jpg", caption:""},
     {url: "/assets/websites/monkeyonmyshoulder_4.jpg", caption:""},
     {url: "/assets/websites/monkeyonmyshoulder_5.jpg", caption:""}],
      flip:'inactive',  
currentSlide:0,
     description:"Webshop für Monkeyonmyshoulder von Gina Drewes. Diese Seite bildet zusammen mit Monkeyonmyshoulder eine Multisiteinstallation mit gekoppeltem Rechnungs-system. Man kann von Bestellungen von mehreren Seiten zusammen abrechnen", 
     usedTech: ["Wordpress-CMS", "Wordpress-Multi-Site", "Woocommerce"],

     year: "2017",
     cols: 2, rows: 2 }
     
     ,
     

     { title: 'homeage.at',     
        subtitle: 'Wordpress',
  
     link:"https://www.homeage.at", 
     img:"/assets/websites/homeage_1.jpg", 
     imgavatar:"/assets/websites/homeage_avatar.png", 

     images:[      
      {url: "/assets/websites/homeage_1.jpg", caption:""},

      {url: "/assets/websites/homeage_2.jpg", caption:""},
     {url: "/assets/websites/homeage_3.jpg", caption:""},
     {url: "/assets/websites/homeage_4.jpg", caption:""},
     {url: "/assets/websites/homeage_5.jpg", caption:""}],
      flip:'inactive',  
currentSlide:0,
     year: "2017",
     description:"Webseite für das Möbelhaus Homeage in 1010 Wien", 
     usedTech: ["Wordpress-CMS"],

     cols: 2, rows: 2 }
     
     ,


     { title: 'Wieder-Aktiv.at',     
        subtitle: 'Wordpress',

       link:"", 
        img:"/assets/websites/wiederaktiv_1.jpg",
        imgavatar:"/assets/websites/wiederaktiv_avatar.jpg", 

        images:[

          {url: "/assets/websites/wiederaktiv_1.jpg", caption:""},

          {url: "/assets/websites/wiederaktiv_2.jpg", caption:""},

          {url: "/assets/websites/wiederaktiv_3.jpg", caption:""},
     {url: "/assets/websites/wiederaktiv_5.jpg", caption:""},
     {url: "/assets/websites/wiederaktiv_6.jpg", caption:""},
     {url: "/assets/websites/wiederaktiv_2.jpg", caption:""}],
      flip:'inactive',  
currentSlide:0,
        year: "2018",
        usedTech: ["Wordpress-CMS"],
     description:"Hosting und Email für die Physiotherapiepraxis Wieder-Aktiv in Schwechat",
      cols: 2, rows: 2 }
      
      ,



     { title: 'sound-sets.com',
     subtitle: 'html5 one page design',
      link:"http://www.sound-sets.com",
      img:"/assets/websites/soundsets_1.jpg", 
      imgavatar:"/assets/logos/soundsets_Metallic-Centercenter500x500.png", 
 
      images:[{url: "/assets/websites/soundsets_1.jpg", caption:""},{url: "/assets/websites/soundsets_2.jpg", caption:""},
      {url: "/assets/websites/soundsets_3.jpg", caption:""},
      {url: "/assets/websites/soundsets_4.jpg", caption:""}],
      //{url: "/assets/software/soundsets_6.jpg", caption:""}
       flip:'inactive',  
 currentSlide:0,
      description:"One Page Design mit Scrolling, Animationen und Navigation Bar sowie einer Serverseitigen PHP Contact Form",
      year: "2017",
      usedTech: ["HTML5", "vanillaJS", "PHP"],
      cols: 2, rows: 2 }
      
      ,

    { title: 'Webhosting + Support',     
        subtitle: 'Linux',

       link:"", 
        img:"/assets/websites/server_3.jpg",
        imgavatar:"/assets/websites/server_avatar.png", 

        images:[{url: "/assets/websites/server_3.jpg", caption:""},{url: "/assets/websites/server_1.jpg", caption:""},
     {url: "/assets/websites/server_2.jpg", caption:""},
     {url: "/assets/websites/server_5.jpg", caption:""},
     {url: "/assets/websites/server_4.jpg", caption:""}],
      flip:'inactive',  
currentSlide:0,
        year: "since 2015",
        usedTech: ["LINUX", "LetsencryptSSL", "DNS",  "PHP", "NodeJS", "GIT", "Email DKIP"],
     description:"Website Hosting per Linux Rootservers, DomainManagement, for Vitalundschoen.com, chakkaboom.com, wieder-aktiv.at, sound-sets.com, homeage.at, backgroundmusic.at",
      cols: 2, rows: 2 },


/*
     { title: 'chakkaboom.com',   
        subtitle: 'Wordpress',
    
     link:"https://www.chakkaboom.com", 
     img:"/assets/websites/chakkaboom_3.png", 
     imgavatar:"/assets/websites/chakkaboom_avatar.png", 

     images:[{url: "/assets/websites/chakkaboom_4.png", caption:"History View"},
            {url: "/assets/websites/chakkaboom_5.png", caption:"1"},
            {url: "/assets/websites/chakkaboom_6.png", caption:"2"},
            {url: "/assets/websites/chakkaboom_7.png", caption:"3"}],

      flip:'inactive',  
currentSlide:0,
year: "2017",
usedTech: ["Wordpress-CMS", "Woocommerce"],
description:"Webshop für Chakkaboom Motorcyclewar Label", 
cols: 2, rows: 2 },*/


     

      
  ]





 
 //2020_SPAR_SBudgetMobile
 //2021_Stickermania
 //2021_SPAR_Genussreif
 //2020_Stickermania
 //2021_SPAR_Grillen
 //2020_SPAR_Sylvesterknaller2021
 //2022_SPAR_NaturPur_Kassabon
 //2020_Splendid
 //2023_DESPAR
 //2023_SPAR_APP_Infinity
 //2023_SPAR_Kochbuch
 //2023_SPAR_Premium
 //2023_SPAR_Rabattmarkerl_Frühling
 //2023_SPAR_Rabattmarkerl_Ostern
 //
  public werbebannercards: Array<IWerbebanner> = [
   
    {
      "title": "DESPAR 2023",
      "subtitle": "Interaktiver HTML5 Banner",
      "link": "assets/werbebanner/2023_DESPAR/500x1000XLr/index.html",
      "img": "assets/werbebanner/2023_DESPAR/500x1000XLr/fallback.jpg",
      "imgavatar": "/assets/logos/sparlogoicon.png",
      "images": [],
      "flip": "inactive",
      "currentSlide": 0,
      "description": "Sitebar XL Interaktiver Banner zu DESPAR 2023",
      "year": "2023",
      "usedTech": ["HTML5", "vanillaJS", "scss", "Gulp", "IAB"],
      "cols": 2,
      "rows": 2
    }
    
       ,
    {
      "title": "SPAR APP Infinity 2023",
      "subtitle": "Interaktiver HTML5 Banner",
      "link": "assets/werbebanner/2023_SPAR_APP_Infinity/500x1000XLr/index.html",
      "img": "assets/werbebanner/2023_SPAR_APP_Infinity/500x1000XLr/fallback.jpg",
      "imgavatar": "/assets/logos/sparlogoicon.png",
      "images": [],
      "flip": "inactive",
      "currentSlide": 0,
      "description": "Sitebar XL Interaktiver Banner zu SPAR APP Infinity 2023",
      "year": "2023",
      "usedTech": ["HTML5", "vanillaJS", "scss", "Gulp", "IAB"],
      "cols": 2,
      "rows": 2
    }
    
       ,
    
    {
      "title": "SPAR Kochbuch 2023",
      "subtitle": "Interaktiver HTML5 Banner",
      "link": "assets/werbebanner/2023_SPAR_Kochbuch/500x1000XLr/index.html",
      "img": "assets/werbebanner/2023_SPAR_Kochbuch/500x1000XLr/fallback.jpg",
      "imgavatar": "/assets/logos/sparlogoicon.png",
      "images": [],
      "flip": "inactive",
      "currentSlide": 0,
      "description": "Sitebar XL Interaktiver Banner zu SPAR Kochbuch 2023",
      "year": "2023",
      "usedTech": ["HTML5", "vanillaJS", "scss", "Gulp", "IAB"],
      "cols": 2,
      "rows": 2
    }
    
    ,
    {
      "title": "SPAR Premium 2023",
      "subtitle": "Interaktiver HTML5 Banner",
      "link": "assets/werbebanner/2023_SPAR_Premium/500x1000XLr/index.html",
      "img": "assets/werbebanner/2023_SPAR_Premium/500x1000XLr/fallback.jpg",
      "imgavatar": "/assets/logos/sparlogoicon.png",
      "images": [],
      "flip": "inactive",
      "currentSlide": 0,
      "description": "Sitebar XL Interaktiver Banner zu SPAR Premium 2023",
      "year": "2023",
      "usedTech": ["HTML5", "vanillaJS", "scss", "Gulp", "IAB"],
      "cols": 2,
      "rows": 2
    }
    
    ,
    {
      "title": "SPAR Rabattmarkerl Frühling 2023",
      "subtitle": "Interaktiver HTML5 Banner",
      "link": "assets/werbebanner/2023_SPAR_Rabattmarkerl_Frühling/500x1000XLr/index.html",
      "img": "assets/werbebanner/2023_SPAR_Rabattmarkerl_Frühling/500x1000XLr/fallback.jpg",
      "imgavatar": "/assets/logos/sparlogoicon.png",
      "images": [],
      "flip": "inactive",
      "currentSlide": 0,
      "description": "Sitebar XL Interaktiver Banner zu SPAR Rabattmarkerl Frühling 2023",
      "year": "2023",
      "usedTech": ["HTML5", "vanillaJS", "scss", "Gulp", "IAB"],
      "cols": 2,
      "rows": 2
    }
    ,
    
    {
      "title": "SPAR Rabattmarkerl Ostern 2023",
      "subtitle": "Interaktiver HTML5 Banner",
      "link": "assets/werbebanner/2023_SPAR_Rabattmarkerl_Ostern/640x960_interaktiv/index.html",
      "img": "assets/werbebanner/2023_SPAR_Rabattmarkerl_Ostern/640x960_interaktiv/fallback.jpg",
      "imgavatar": "/assets/logos/sparlogoicon.png",
      "images": [],
      "flip": "inactive",
      "currentSlide": 0,
      "description": "Sitebar XL Interaktiver Banner zu SPAR Rabattmarkerl Ostern 2023",
      "year": "2023",
      "usedTech": ["HTML5", "vanillaJS", "scss", "Gulp", "IAB"],
      "cols": 2,
      "rows": 2
    }
    ,
    { title: 'SPAR Stickermania 2023',
    subtitle: 'Interaktiver HTML5 Banner',
     link:"assets/werbebanner/2023_Stickermania/500x1000XLr_interaktiv/index.html",
     img:"assets/werbebanner/2023_Stickermania/500x1000XLr_interaktiv/fallback.jpg", 
     imgavatar:"/assets/logos/sparlogoicon.png", 
     images:[  ],
    flip:'inactive',  
     currentSlide:0,
     description:"Sitebar XL Interaktiver Banner zu SPAR Stickermania 2023",
     year: "2022",
     usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB"],  
     cols: 2, rows: 2 }  ,


      { title: 'SPAR NaturPur Slider 2022',
      subtitle: 'Interaktiver HTML5 Banner',
       link:"assets/werbebanner/2022_SPAR_NaturPur_Slider/500x1000XLr/index.html",
       img:"assets/werbebanner/2022_SPAR_NaturPur_Slider/500x1000XLr/fallback.jpg", 
       imgavatar:"/assets/logos/sparlogoicon.png", 
       images:[  ],
      flip:'inactive',  
       currentSlide:0,
       description:"Sitebar XL Interaktiver Banner zu SPAR NaturPur Slider 2022",
       year: "2022",
       usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB"],  
       cols: 2, rows: 2 }  ,


    { title: 'Stickermania 2021',
    subtitle: 'Interaktiver HTML5 Banner',
     link:"assets/werbebanner/2021_Stickermania/500x1000XLr/index.html",
     img:"assets/werbebanner/2021_Stickermania/500x1000XLr/fallback.jpg", 
     imgavatar:"/assets/logos/sparlogoicon.png", 
     images:[  ],
    flip:'inactive',  
     currentSlide:0,
     description:"Sitebar XL Interaktiver Banner zu Stickermania 2021",
     year: "2021",
     usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB", "Adobe"],  
     cols: 2, rows: 2 }    
  
   ,

  
    { title: 'SPAR Rabattrechner 2022',
    subtitle: 'Interaktiver HTML5 Banner',
     link:"assets/werbebanner/2022_SPAR_Rabattrechner/500x1000XLr/index.html",
     img:"assets/werbebanner/2022_SPAR_Rabattrechner/500x1000XLr/fallback.jpg", 
     imgavatar:"/assets/logos/sparlogoicon.png", 
     images:[  ],
    flip:'inactive',  
     currentSlide:0,
     description:"Sitebar XL Interaktiver Banner zu SPAR Rabattrechner 2022",
     year: "2022",
     usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB"],  
     cols: 2, rows: 2 }  

   ,
   { title: 'SPAR SBudget Mobile 2020',
   subtitle: 'animierter HTML5 Banner mit 3D Effekt',
    link:"assets/werbebanner/2020_SPAR_SBudgetMobile/500x1000XLr/index.html",
    img:"assets/werbebanner/2020_SPAR_SBudgetMobile/500x1000XLr/fallback.jpg", 
    imgavatar:"/assets/logos/sparlogoicon.png", 
   
    images:[
     {url: "/assets/software/soundsetsionic_3.png", caption:"History View"},
   {url: "/assets/software/soundsetsionic_5.png", caption:""},
   //  {url: "/assets/software/soundsetsionic_6.png", caption:"2"},
   //  {url: "/assets/software/soundsetsionic_2.png", caption:"3"}
       ],
     flip:'inactive',  
   currentSlide:0,
    description:"Sitebar XL animierter Banner mit 3D Effekt zu SPAR SBudget Mobile",
    year: "2020",
    usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB", "Adobe", "Page84", "Wirz", "SPAR"],
    cols: 2, rows: 2 }     
    ,
    { title: 'Stickermania 2020',
    subtitle: 'Interaktiver HTML5 Banner',
     link:"assets/werbebanner/2020_Stickermania/500x1000XLr/index.html",
     img:"assets/werbebanner/2020_Stickermania/500x1000XLr/fallback.jpg", 
     imgavatar:"/assets/logos/sparlogoicon.png", 

     images:[
   
        ],
      flip:'inactive',  
currentSlide:0,
     description:"Sitebar XL Interaktiver Banner zu Stickermania 2021",
     year: "2021",
     usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB", "Adobe", "Page84", "Wirz", "SPAR"],
     cols: 2, rows: 2 }        
     ,
    
     
      { title: 'TSystems 2022',
      subtitle: 'animierter HTML5 Banner',
       link:"assets/werbebanner/2022_TSystems/300x600f/index.html",
       img:"assets/werbebanner/2022_TSystems/300x600f/fallback.jpg", 
       imgavatar:"/assets/logos/tsystems.png", 
       images:[  ],
      flip:'inactive',  
       currentSlide:0,
       description:"Sitebar XL animierter Banner zu TSystems",
       year: "2022",
       usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB"],  
       cols: 2, rows: 2 }  ,



     { title: 'SPAR Genussreif 2021',
     subtitle: 'animierter HTML5 Banner',
      link:"assets/werbebanner/2021_SPAR_Genussreif/500x1000XLr/index.html",
      img:"assets/werbebanner/2021_SPAR_Genussreif/500x1000XLr/fallback.jpg", 
      imgavatar:"/assets/logos/sparlogoicon.png",  
      images:[     ],
       flip:'inactive',  
 currentSlide:0,
      description:"Sitebar XL Interaktiver Banner zu SPAR Genussreif",
      year: "2021",
      usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB", "Adobe", "Page84", "Wirz", "SPAR"],
      cols: 2, rows: 2 }       
,

{ title: 'SPAR Grillen 2021',
subtitle: 'animierter HTML5 Banner',
 link:"assets/werbebanner/2021_SPAR_Grillen/500x1000XLr/index.html",
 img:"assets/werbebanner/2021_SPAR_Grillen/500x1000XLr/fallback.jpg", 
 imgavatar:"/assets/logos/sparlogoicon.png", 

 images:[
  {url: "/assets/software/soundsetsionic_3.png", caption:""},
{url: "/assets/software/soundsetsionic_5.png", caption:""},
//  {url: "/assets/software/soundsetsionic_6.png", caption:"2"},
//  {url: "/assets/software/soundsetsionic_2.png", caption:"3"}
    ],
  flip:'inactive',  
currentSlide:0,
 description:"Sitebar XL Interaktiver Banner zu SPAR Grillen",
 year: "2021",
 usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB", "Adobe", "Page84", "Wirz", "SPAR"],
 cols: 2, rows: 2 }      


 ,



  { title: 'SPAR Sylvesterknaller 2021',
  subtitle: 'Interaktiver HTML5 Banner',
   link:"assets/werbebanner/2020_SPAR_Sylvesterknaller2021/500x1000XLr/index.html",
   img:"assets/werbebanner/2020_SPAR_Sylvesterknaller2021/500x1000XLr/fallback.jpg", 
   imgavatar:"/assets/logos/sparlogoicon.png", 
  
   images:[
    {url: "/assets/software/soundsetsionic_3.png", caption:""},
  {url: "/assets/software/soundsetsionic_5.png", caption:""},
  //  {url: "/assets/software/soundsetsionic_6.png", caption:"2"},
  //  {url: "/assets/software/soundsetsionic_2.png", caption:"3"}
      ],
    flip:'inactive',  
  currentSlide:0,
   description:"Sitebar XL Interaktiver Banner zu SPAR Sylvesterknaller 2021",
   year: "2021",
   usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB", "Adobe", "Page84", "Wirz", "SPAR"],
   cols: 2, rows: 2 }     
 
   ,

   { title: 'SPAR NaturPurKassabon 2020',
   subtitle: 'animierter HTML5 Banner',
    link:"assets/werbebanner/2020_SPAR_NaturPurKassabon/500x1000XLr/index.html",
    img:"assets/werbebanner/2020_SPAR_NaturPurKassabon/500x1000XLr/fallback.jpg", 
    imgavatar:"/assets/logos/sparlogoicon.png", 
   
    images:[
     {url: "/assets/software/soundsetsionic_3.png", caption:""},
   {url: "/assets/software/soundsetsionic_5.png", caption:""},
   //  {url: "/assets/software/soundsetsionic_6.png", caption:"2"},
   //  {url: "/assets/software/soundsetsionic_2.png", caption:"3"}
       ],
     flip:'inactive',  
   currentSlide:0,
    description:"Sitebar XL animierter Banner zu SPAR NaturPurKassabon 2020",
    year: "2021",
    usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB", "Adobe", "Page84", "Wirz", "SPAR"],
    cols: 2, rows: 2 }     
    ,

    { title: 'SPAR SPLENDID 2020',
    subtitle: 'HTML5 Banner',
     link:"assets/werbebanner/2020_Splendid/500x1000XLr/index.html",
     img:"assets/werbebanner/2020_Splendid/500x1000XLr/fallback.jpg", 
     imgavatar:"/assets/logos/sparlogoicon.png", 
    
     images:[
      {url: "/assets/software/soundsetsionic_3.png", caption:""},
    {url: "/assets/software/soundsetsionic_5.png", caption:""},
    //  {url: "/assets/software/soundsetsionic_6.png", caption:"2"},
    //  {url: "/assets/software/soundsetsionic_2.png", caption:"3"}
        ],
      flip:'inactive',  
    currentSlide:0,
     description:"Sitebar XL animierter Banner zu SPLENDID Putzmittel",
     year: "2021",
     usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB", "Adobe", "Page84", "Wirz", "SPAR"],
     cols: 2, rows: 2 }     
    ]
 
    public softwareCards :Array<ISoftwareData>=  [
      { title: 'SoundSets Player',
      subtitle: 'Streaming Music Player',
       link:"http://www.sound-sets.com",
       img:"/assets/software/soundsetsplayer_1.jpg",    
       img2:"/assets/logos/soundsets_Metallic-Centercenter700x700.png", 
     images:[{url: "/assets/software/soundsetsplayer_1.jpg", caption:""},
     {url: "/assets/software/soundsetsplayer_1.jpg", caption:""},
    
    
    ],
    
        currentSlide:0,
       description:"First Version of the Sound-Sets Music Player. It features an encrypted Offline Cache and Automatic Content Updates, and Offline License Protection. It can serve Multiple Zones, show Background Image's, play scheduled Jingles, bootup and login automatically, EQ+Compressor, Schedule, HTTP-Streaming via Icecast2",
       year: "2015",
       usedTech: ["C#", "WPF", "PHP", "BASS.NET", "Icecast2", "LAME", "Serverside: PHP+SQL"],
       cols: 2, rows: 2 , flip: "inactive"},        
  
       { title: 'Sound-Sets WebPlayer',     
       subtitle: 'Streaming Music Player',
       link:"http://www.sound-sets.com/ionic", 
       img:"/assets/software/soundsetsionic_2.png", 
       img2:"/assets/logos/soundsets_Metallic-Centercenter700x700.png", 
       images:[{url: "/assets/software/soundsetsionic_1.png", caption:""},
       {url: "/assets/software/soundsetsionic_4.png", caption:""},
       {url: "/assets/software/soundsetsionic_5.png", caption:""},
       {url: "/assets/software/soundsetsionic_6.png", caption:""},
       {url: "/assets/software/soundsetsionic_2.png", caption:""}
      ],
      currentSlide:0,
      year: "2017",
        usedTech: ["IonicV1", "AngularV1",   "PHP", "SQL", "WebAudio", "Backend: PHP+Jquery+Bootstrap"],
        description:"The Sound-Sets Music WebPlayer was made using the IONIC Mobile Web App Framework. It features Audio Preloading, Seamless Transitions and 1h Offline Cache",
      cols: 2, rows: 2 , flip: "inactive"},
      
      { title: 'SoundSets App V2',      
      subtitle: 'Streaming Music Player',
       link:"http://www.sound-sets.com", 
       img:"/assets/software/soundsetsappv2_8.png",
       img2:"/assets/logos/soundsets_Metallic-Centercenter700x700.png", 
       images:[        {url: "/assets/software/soundsetsappv2_8.png", caption:""},

        {url: "/assets/software/soundsetsappv2_6.png", caption:"Select a Playlist"},
       {url: "/assets/software/soundsetsappv2_2.png", caption:"Search and Play Track"},
       {url: "/assets/software/soundsetsappv2_5.png", caption:"Waveform View"},
       {url: "/assets/software/soundsetsappv2_7.png", caption:"Application Settings"},
       
      ],
      currentSlide:0,
        description:"Music Database", 
       usedTech: ["C#", "WPF", "MVVM", "DI", "BASS.NET", "Icecast2", "LAME", "Serverside: PHP+SQL"],
       year: "2017",
       cols: 2, rows: 2 , flip: "inactive"},
  
       { title: 'SoundSets App V2 Backend',      
       subtitle: 'Music Database',
       link:"http://www.sound-sets.com", 
       img:"/assets/software/soundsetsbackendv2_1.png", 
       img2:"/assets/logos/soundsets_Metallic-Centercenter700x700.png", 
       images:[        {url: "/assets/software/soundsetsbackendv2_1.png", caption:""},

        {url: "/assets/software/soundsetsbackendv2_3.png", caption:"Create a Schedule"},
        {url: "/assets/software/soundsetsbackendv2_4.png", caption:"Active Clients"},
        {url: "/assets/software/soundsetsbackendv2_5.png", caption:"Client List"},
        {url: "/assets/software/soundsetsbackendv2_6.png", caption:"Client Set Export and Update"},
        {url: "/assets/software/soundsetsbackendv2_7.png", caption:"Playlists View"}
       ],
       currentSlide:0,
       description:"Music Database for Sound-Sets App V2, using AngularV1, NodeJS+Express, MongoDB and File Storage. Continious Integration using GIT and POST-Upload Hooks", 
       usedTech: ["NodeJS", "Express", "AngularV1", "MongoDB"],
       year: "2016",
       cols: 2, rows: 2 , flip: "inactive"},
  
       { title: 'IcyCompSC VST3 Compressor',     
       subtitle: 'Realtime Audio Effect with Sidechain', 
       link:"", 
       img:"/assets/software/IcycompSC.jpg", 
       img2:"/assets/software/IcycompSC.jpg", 
       images:[
        {url: "/assets/software/IcycompWIP201504.jpg", caption:""},
        {url: "/assets/software/IcycompWIP201502.jpg", caption:"Made with VSTGUI and Blender"},
       
       ],
       currentSlide:0,
       description:"Compressor with Bandsplitter and external Sidechain. The Knobs are logarithmic to allow finer dialing of the Sweet Spots. The UI is Rendered in Blender and integrated within VSTGUI.", 
       usedTech: ["C++", "Steinberg VST3 Framework", "VSTGUI", "Blender UI Rendering", "C# for tooling"],
  
       year: "2017",
       cols: 2, rows: 2 , flip: "inactive"},
  
       { title: 'Bitwig OSC Scripting',      
       subtitle: 'Remote Controlling an Audio Sequencer',
       link:"https://www.bitwig.com", 
       img:"/assets/software/bitwigscreenshot3.JPG", 
       img2:"/assets/logos/bitwiglogo500x500.png", 
       images:[
        {url: "/assets/software/bitwigscreenshot3.JPG", caption:""},

        {url: "/assets/software/bitwigscreenshot4.JPG", caption:""},

        {url: "/assets/software/bitwigscreenshot5.JPG", caption:""},

        {url: "/assets/software/bitwigscreenshot.JPG", caption:""},
        {url: "/assets/software/bitwigscreenshot2.JPG", caption:""},

       ],
       currentSlide:0,
       year: "2017",
       description:"Modification of the DesignedByMoss-Bitwig-OSC Script to use Multiple Pinnable Device's for IP Connected Control Surfaces, for the CAMO & KROOKED Live Show", 
       usedTech: ["Bitwig", "JAVA", "MAVEN", "OSC", "DesignedByMoss"],
  
       cols: 2, rows: 2 , flip: "inactive"},
  
      { title: 'IN:DEEP Game / Breakout Clone',     
      subtitle: 'Javascript Webgame',
         link:"https://indeep.backgroundmusic.at", 
         img:"/assets/software/indeepgame_2.png", 
         img2:"/assets/logos/indeepsoundcloud.png", 
         images:[
          {url: "/assets/software/indeepgame_1.png", caption:"Startup Screen"},
          {url: "/assets/software/indeepgame_2.png", caption:"Game Screen"},
          {url: "/assets/software/indeepgame_3.png", caption:"Score Screen"},
          
          
         ],
         currentSlide:0,
         year: "2017",
          usedTech: ["Phaser.IO", "Typescript",  "Gulp", "PHP", "Postgre SQL", "REST"],
       description:"Breakout-Clone with Powerups, Multiple Balls, Music Integration, Server-Side PHP-SQL-REST-Scoreboard",
        cols: 2, rows: 2 , flip: "inactive"},
      
    
    ];




    clubevents: Array<IClubevents> = [
     
      {
        name :"Mainframe",
        occupation: "Working as a Resident DJ since 2008",
        description : "Longest running monthly Drumandbass Night in Austria",
        locations: ["Arena",  "WUK", "TheZoo", "Donauinselfest", "Praterfestival", "Badeschiff"],
        avg_visitors_per_party: 1500,
        founded: new Date(2002,1,1),
        ended: null,
        frequency: "monthly",
        img_main_url: "/assets/music/clubevents/mainframe1.jpg",
        img_logo_url:"/assets/music/clubevents/mainframe_logo.png", 
        link:"https://www.mainframerecordings.at", 
  
        occupations: ["Resident DJ", "Social-Media"]
      },
      {
        name :"Beat-It",
        description : "Weekly drumandbass Clubnight at the legendary Flex",
        occupation: "I've been a Resident DJ at Drumandbass Club's in Flex since 2004",

        locations: ["Flex", "Flex-Cafe"],
        avg_visitors_per_party: 700,
        founded: new Date(2002,1,1),
        ended: null,
        frequency: "monthly",
        img_main_url: "/assets/music/clubevents/beatit1.jpg",
        img_logo_url:"/assets/music/clubevents/beatit_logo.png", 
        link:"https://www.flex.at", 
  
        occupations: ["Resident DJ", "Social-Media"]
      },
      {
        name :"Upgrade",
        description : "Monthly drumandbass Clubnight at the legendary Flex",
        occupation: "I've been a Resident DJ at Drumandbass Club's in Flex since 2004",

        locations: ["Flex", "Flex-Cafe"],
        avg_visitors_per_party: 700,
        founded: new Date(2019,1,1),
        ended: null,
        frequency: "monthly",
        img_main_url: "/assets/music/clubevents/upgrade3.jpg",
        img_logo_url:"/assets/music/clubevents/uprade_logo.jpg", 
        link:"https://www.facebook.com/FlexVienna/photos", 
  
        occupations: ["Resident DJ", "Social-Media"]
      },
      {
        name :"The Booth",
        description : "Streaming DJ Sets in times of the Corona Pandemic",
        occupation: "Resident DJ since 2019",

        locations: ["Loft","Black Market", "Flex", "The Booth - Studio"],
        avg_visitors_per_party: 700,
        founded: new Date(2019,1,1),
        ended: null,
        frequency: "half-year",
        img_main_url: "/assets/music/clubevents/thebooth_lg.jpg",
        img_logo_url:"/assets/music/clubevents/thebooth_sm.jpg", 
        link:"https://www.youtube.com/watch?v=oSZWSmwdvxM&t=324s", 
  
        occupations: ["Resident DJ", "Social-Media"]
      },
      {
        name :"Dubstep.at",
        description : "First Austrian Dubstep Community, founded by Atomique",
        occupation: "My main occupation was resident DJ, Website and Social Media",
        locations: ["Badeschiff", "Arena", "WUK", "TheZoo"],
        avg_visitors_per_party: 500,
        founded: new Date(2006,1,1),
        ended: new Date(2014,1,1),
        frequency: "monthly",
        img_main_url: "/assets/music/clubevents/dubstepat2.jpg",
        img_logo_url:"/assets/music/clubevents/dubstepat_logo.png", 
        link:"https://www.dubstep.at", 
  
        occupations: ["Social-Media", "Website", "Wordpress", "Resident DJ"]
      },
    ]


  constructor(){

this.werbebannercards.push(   
    { title: 'SPAR NaturPur 2019',
    subtitle: 'Interaktiver HTML5 Banner',
     link:"assets/werbebanner/2019_SPAR_NaturPur/500x1000XLr/index.html",
     img:"assets/werbebanner/2019_SPAR_NaturPur/500x1000XLr/fallback.jpg", 
     imgavatar:"/assets/logos/sparlogoicon.png", 
     images:[  ],
    flip:'inactive',  
     currentSlide:0,
     description:"Sitebar XL animierter Banner zu SPAR NaturPur 2019",
     year: "2019",
     usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB", "Adobe"],  
     cols: 2, rows: 2 }  );

     this.werbebannercards.push(   
      { title: 'SPAR Plastik Sunkiss 2019',
      subtitle: 'Interaktiver HTML5 Banner',
       link:"assets/werbebanner/2019_SPAR_Plastik_Sunkiss/500x1000XLr/index.html",
       img:"assets/werbebanner/2019_SPAR_Plastik_Sunkiss/500x1000XLr/images/fallback.jpg", 
       imgavatar:"/assets/logos/sparlogoicon.png", 
       images:[  ],
      flip:'inactive',  
       currentSlide:0,
       description:"Sitebar XL animierter Banner zu SPAR Plastik Sunkiss 2019",
       year: "2019",
       usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB", "Adobe"],  
       cols: 2, rows: 2 }  );


       this.werbebannercards.push(   
        { title: 'SPAR Sylvester 2019',
        subtitle: 'Interaktiver HTML5 Banner',
         link:"assets/werbebanner/2019_SPAR_Sylvester/500x1000XLr/index.html",
         img:"assets/werbebanner/2019_SPAR_Sylvester/500x1000XLr/fallback.jpg", 
         imgavatar:"/assets/logos/sparlogoicon.png", 
         images:[  ],
        flip:'inactive',  
         currentSlide:0,
         description:"Sitebar XL animierter Banner zu SPAR Sylvester 2019",
         year: "2019",
         usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB", "Adobe"],  
         cols: 2, rows: 2 }  );

         this.werbebannercards.push(   
          { title: 'SPAR Vital Kuehlkschrank 2019',
          subtitle: 'Interaktiver HTML5 Banner',
           link:"assets/werbebanner/2019_SPAR_Vital_Kuehlkschrank/500x1000XLr/index.html",
           img:"assets/werbebanner/2019_SPAR_Vital_Kuehlkschrank/500x1000XLr/images/fallback.jpg", 
           imgavatar:"/assets/logos/sparlogoicon.png", 
           images:[  ],
          flip:'inactive',  
           currentSlide:0,
           description:"Sitebar XL animierter Banner zu SPAR Vital Kuehlkschrank 2019",
           year: "2019",
           usedTech: ["HTML5", "vanillaJS", "css", "IAB", "Adobe"],  
           cols: 2, rows: 2 }  );

           this.werbebannercards.push(   
            { title: 'Hervis Aftereaster 2020',
            subtitle: 'animierter HTML5 Banner',
             link:"assets/werbebanner/2020_Hervis_Aftereaster/500x1000XLr/index.html",
             img:"assets/werbebanner/2020_Hervis_Aftereaster/500x1000XLr/fallback.jpg", 
             imgavatar:"/assets/logos/hervis.png", 
             images:[  ],
            flip:'inactive',  
             currentSlide:0,
             description:"Sitebar XL animierter Banner zu Hervis Aftereaster 2020",
             year: "2020",
             usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB", "Adobe"],  
             cols: 2, rows: 2 }  );

             this.werbebannercards.push(   
              { title: 'SPAR Rabattmarkerl Herbst 2020',
              subtitle: 'animierter HTML5 Banner',
               link:"assets/werbebanner/2020_SPAR_Rabattmarkerl_Herbst/500x1000XLr/index.html",
               img:"assets/werbebanner/2020_SPAR_Rabattmarkerl_Herbst/500x1000XLr/fallback.jpg", 
               imgavatar:"/assets/logos/sparlogoicon.png", 
               images:[  ],
              flip:'inactive',  
               currentSlide:0,
               description:"Sitebar XL animierter Banner zu SPAR NaturPurKassabon 2020",
               year: "2020",
               usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB"],  
               cols: 2, rows: 2 }  );

               
             this.werbebannercards.push(   
              { title: 'SPAR XMas 2020',
              subtitle: 'animierter HTML5 Banner',
               link:"assets/werbebanner/2020_SPAR_XMas/500x1000XLr/index.html",
               img:"assets/werbebanner/2020_SPAR_XMas/500x1000XLr/fallback.jpg", 
               imgavatar:"/assets/logos/sparlogoicon.png", 
               images:[  ],
              flip:'inactive',  
               currentSlide:0,
               description:"Sitebar XL animierter Banner zu SPAR XMas 2020",
               year: "2020",
               usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB", "Adobe"],  
               cols: 2, rows: 2 }  );

               this.werbebannercards.push(   
                { title: 'YoungAndUrban 2020',
                subtitle: 'Interaktiver HTML5 Banner',
                 link:"assets/werbebanner/2020_YoungAndUrban_Allgemein/500x1000XLr/index.html",
                 img:"assets/werbebanner/2020_YoungAndUrban_Allgemein/500x1000XLr/fallback.jpg", 
                 imgavatar:"/assets/logos/sparlogoicon.png", 
                 images:[  ],
                flip:'inactive',  
                 currentSlide:0,
                 description:"Sitebar XL Interaktiver Banner zu YoungAndUrban 2020 Allgemein",
                 year: "2020",
                 usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB", "Adobe"],  
                 cols: 2, rows: 2 }  );

                 this.werbebannercards.push(   
                  { title: 'YoungAndUrban Wholey 2020',
                  subtitle: 'Interaktiver HTML5 Banner',
                   link:"assets/werbebanner/2020_YoungAndUrban_Wholey/500x1000XLr/index.html",
                   img:"assets/werbebanner/2020_YoungAndUrban_Wholey/500x1000XLr/fallback.jpg", 
                   imgavatar:"/assets/logos/sparlogoicon.png", 
                   images:[  ],
                  flip:'inactive',  
                   currentSlide:0,
                   description:"Sitebar XL Interaktiver Banner zu YoungAndUrban Wholey 2020",
                   year: "2020",
                   usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB"],  
                   cols: 2, rows: 2 }  );


                   this.werbebannercards.push(   
                    { title: 'SPAR FutureChain 2021',
                    subtitle: 'animierter HTML5 Banner',
                     link:"assets/werbebanner/2021_FutureChain/500x1000XLr/index.html",
                     img:"assets/werbebanner/2021_FutureChain/500x1000XLr/fallback.jpg", 
                     imgavatar:"/assets/logos/sparlogoicon.png", 
                     images:[  ],
                    flip:'inactive',  
                     currentSlide:0,
                     description:"Sitebar XL animierter Banner zu SPAR FutureChain 2021",
                     year: "2020",
                     usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB"],  
                     cols: 2, rows: 2 }  );

                     this.werbebannercards.push(   
                      { title: 'SPAR Babynahrung 2021',
                      subtitle: 'Interaktiver HTML5 Banner',
                       link:"assets/werbebanner/2021_SPAR_Babynahrung/500x1000XLr/index.html",
                       img:"assets/werbebanner/2021_SPAR_Babynahrung/500x1000XLr/fallback.jpg", 
                       imgavatar:"/assets/logos/sparlogoicon.png", 
                       images:[  ],
                      flip:'inactive',  
                       currentSlide:0,
                       description:"Sitebar XL Interaktiver Banner zu SPAR Babynahrung 2021",
                       year: "2020",
                       usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB"],  
                       cols: 2, rows: 2 }  );

                       this.werbebannercards.push(   
                        { title: 'SPAR Bienen 2021',
                        subtitle: 'animierter HTML5 Banner',
                         link:"assets/werbebanner/2021_SPAR_Bienen/500x1000XLr/index.html",
                         img:"assets/werbebanner/2021_SPAR_Bienen/500x1000XLr/fallback.jpg", 
                         imgavatar:"/assets/logos/sparlogoicon.png", 
                         images:[  ],
                        flip:'inactive',  
                         currentSlide:0,
                         description:"Sitebar XL animierter Banner zu SPAR Bienen 2021",
                         year: "2020",
                         usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB"],  
                         cols: 2, rows: 2 }  );



                         this.werbebannercards.push(   
                          { title: 'Hervis 2022',
                          subtitle: 'HTML5 Video Banner',
                           link:"assets/werbebanner/2022_Hervis/500x1000XLr/index.html",
                           img:"assets/werbebanner/2022_Hervis/500x1000XLr/fallback.jpg", 
                           imgavatar:"/assets/logos/sparlogoicon.png", 
                           images:[  ],
                          flip:'inactive',  
                           currentSlide:0,
                           description:"Sitebar XL Video Banner zu Hervis 2022",
                           year: "2022",
                           usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB"],  
                           cols: 2, rows: 2 }  );
    
                           this.werbebannercards.push(   
                            { title: 'SPAR Employee 2022',
                            subtitle: 'animierter HTML5 Banner',
                             link:"assets/werbebanner/2022_SPAR_Employee/500x1000XLr/index.html",
                             img:"assets/werbebanner/2022_SPAR_Employee/500x1000XLr/fallback.jpg", 
                             imgavatar:"/assets/logos/sparlogoicon.png", 
                             images:[  ],
                            flip:'inactive',  
                             currentSlide:0,
                             description:"Sitebar XL animierter Banner zu SPAR Employee 2022",
                             year: "2022",
                             usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB"],  
                             cols: 2, rows: 2 }  );
      
                           
        
                               this.werbebannercards.push(   
                                { title: 'SPAR Rabattmarkerl Sep. 2022',
                                subtitle: 'animierter HTML5 Banner',
                                 link:"assets/werbebanner/2022_SPAR_Rabattmarkerl_September/500x1000XLr/index.html",
                                 img:"assets/werbebanner/2022_SPAR_Rabattmarkerl_September/500x1000XLr/fallback.jpg", 
                                 imgavatar:"/assets/logos/sparlogoicon.png", 
                                 images:[  ],
                                flip:'inactive',  
                                 currentSlide:0,
                                 description:"Sitebar XL animierter Banner zu SPAR Rabattmarkerl September 2022",
                                 year: "2022",
                                 usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB"],  
                                 cols: 2, rows: 2 }  );


                               

                                   this.werbebannercards.push(   
                                    { title: 'SPAR Xmas 2022',
                                    subtitle: 'animierter HTML5 Banner',
                                     link:"assets/werbebanner/2022_SPAR_Xmas/500x1000XLr/index.html",
                                     img:"assets/werbebanner/2022_SPAR_Xmas/500x1000XLr/fallback.jpg", 
                                     imgavatar:"/assets/logos/sparlogoicon.png", 
                                     images:[  ],
                                    flip:'inactive',  
                                     currentSlide:0,
                                     description:"Sitebar XL animierter Banner zu SPAR Xmas 2022",
                                     year: "2022",
                                     usedTech: ["HTML5", "vanillaJS", "scss", "Gulp", "IAB"],  
                                     cols: 2, rows: 2 }  );


                                    
      
    

                                

    
  }






}
