import { Component, OnInit } from '@angular/core';
import { Injectable} from '@angular/core';

import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {BehaviorSubject} from 'rxjs';
import { ResumeproviderService, ISoftskill, ISoftskillDesc } from 'src/app/services/resumeprovider.service';


@Component({
  selector: 'app-softskills',
  templateUrl: './softskills.component.html',
  styleUrls: ['./softskills.component.scss']
})
export class SoftskillsComponent implements OnInit {

softskills: Array<ISoftskill>;

  constructor(resumeproviderService: ResumeproviderService) { 

    this.softskills = resumeproviderService.softskills;
  }
  
  
  ngOnInit() {
  }


  

}