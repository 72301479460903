import {ComponentRef, Injectable, Component, 
  ComponentFactory, 
  ComponentFactoryResolver,Injector, ViewContainerRef, ViewChild, ViewChildren, QueryList
  
  } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';

import { BiographyComponent } from '../dashboard/biography/biography.component';

import { SkillsComponent } from '../dashboard/skills/skills.component';
import { SoftskillsComponent } from '../dashboard/softskills/softskills.component';

import { HobbiesComponent } from '../dashboard/hobbies/hobbies.component';
import { divBannerSlideHeight } from '../animations/divBannerSlideAnimation';
import { ChangeDetectorRef } from '@angular/core';

interface IDashboardCard{

  title:string,
  cardOpened: boolean,
  loadcomplete: boolean,
  component: any,
  componentName: string
  cols:number,
  rows:number,

}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [divBannerSlideHeight],
  entryComponents: [
    SkillsComponent, HobbiesComponent, BiographyComponent, SoftskillsComponent
    ]
})
export class DashboardComponent {
  @ViewChildren('cardComponentContainer', {read: ViewContainerRef}) cardComponentContainers:QueryList<SkillsComponent>;
 // @ViewChild("cardComponentContainer", {read: ViewContainerRef}) cardComponentContainer ;
 // @ViewChild(AdDirective) adHost: AdDirective;
  interval: any;
 // cmp: ComponentRef;
 cards: Array<IDashboardCard>;


  constructor(private breakpointObserver: BreakpointObserver, 
    private componentFactoryResolver: ComponentFactoryResolver ,
     private viewContainerRef: ViewContainerRef,private ref: ChangeDetectorRef
     ) {
      this.cards= [      
        { title: 'Biographie',cardOpened:true, loadcomplete:false, component: BiographyComponent, componentName: "BiographyComponent", cols: 1, rows: 1 },
        { title: 'Softskills',cardOpened:true,   loadcomplete:false, component: SoftskillsComponent, componentName: "SoftskillsComponent",  cols: 1, rows: 1 },
        { title: 'Technical Skills',cardOpened:true ,  loadcomplete:false, component: SkillsComponent, componentName: "SkillsComponent",    cols: 2, rows: 1 },
        { title: 'Hobbies',cardOpened:true,  loadcomplete:false, component: HobbiesComponent, componentName: "HobbiesComponent",   cols: 1, rows: 1 },
      ];
    }

 ngOnInit(): void {
   //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
   //Add 'implements OnInit' to the class.
    var self=this;
    this.loadData();

  
  
 }

 ngAfterViewInit(): void {
   //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
   //Add 'implements AfterViewInit' to the class.
  // this.createComponent("SkillsComponent");
  this.interval = setTimeout(() => {

    this.loadJson();

    this.looadcomponents();
   

   
  }, 10);
 }

looadcomponents(){
  var self=this;
  let i=1;
  self.cardComponentContainers.forEach(element => {
   // console.log("loading ", element);
    setTimeout(() => {    

      self.loadComponent(element);
      
    }, (Math.random() * i*100));
    i++;i++;
});
}

  loadComponent(element) {
    //this.currentAdIndex = (this.currentAdIndex + 1) % this.ads.length;
    element._view.context.$implicit.loadcomplete=true;

const comp= element._view.context.$implicit.component;

//console.log(element, comp);
//this.viewContainerRef.clear();
    //if(element.component){return;}
    const componentFactory = this.componentFactoryResolver.
              resolveComponentFactory(comp);
    const ref = element.createComponent(componentFactory);
   

   // const componentRef = this.viewContainerRef.createComponent(componentFactory);
   // (<SkillsComponent>componentRef.instance).data = componentItem.data;
  }
  /*
    ngOnDestroy() {
    }
  /** Based on the screen size, switch from standard to one column per row */
loadJson(){
}

loadData(){

let self=this;

  let subscription = this.breakpointObserver.observe(
    [Breakpoints.Handset, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(
    ( result ) => {
      console.log(result);
      if (result.matches) {
        if(                             
        result.breakpoints['(min-width: 1920px)']       
        ){
          this.activateDesktopLarge(result, self);

        }else if(result.breakpoints['(min-width: 960px) and (max-width: 1279.99px)']
        || result.breakpoints['(min-width: 1280px) and (max-width: 1919.99px)']){
          this.activateDesktopMedium(result, self);
        }  
        
        
        else{
      this.activateHandsetLayout(result, self );
        }
      } else {

       
        this.activateDesktopMedium(result, self);}
      
  //    this.cards = cards;
    });
}

activateDesktopLarge(result,self){

  // console.log("actHandset", result,  this.cards);
 
     
      //desktop medium
     self.cards[0].cols= 1; self.cards[0].rows= 2 ;//bio
     self.cards[1].cols= 1; self.cards[1].rows= 4 ;//c4
     self.cards[2].cols= 2; self.cards[2].rows= 4 ;//skills
     self.cards[3].cols= 1; self.cards[3].rows= 2 ;//hobby
       
 }

activateDesktopMedium(result,self){

 // console.log("actHandset", result,  this.cards);

    
     //desktop medium
    self.cards[0].cols= 2; self.cards[0].rows= 2 ;//bio
    self.cards[1].cols= 2; self.cards[1].rows= 2 ;//c4
    self.cards[2].cols= 4; self.cards[2].rows= 6 ;//skills
    self.cards[3].cols= 2; self.cards[3].rows= 2 ;//hobby
      
}
activateHandsetLayout(result,self){{//mobile
  
   self.cards[0].cols= 4;self.cards[0].rows= 2 ;//bio
   self.cards[1].cols= 4;self.cards[1].rows= 2 ;//c4
   self.cards[2].cols= 4;self.cards[2].rows= 4 ;//skills
   self.cards[3].cols= 4;self.cards[3].rows= 2 ;//hobby
}
   
 

}

toggleCardOpened(card: IDashboardCard){
  console.log(card);
  card.cardOpened= !card.cardOpened;
  this.ref.detectChanges();
}

  
}
