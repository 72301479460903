import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';
import { fromEvent , BehaviorSubject, Observable, empty } from 'rxjs';
import { map, share } from 'rxjs/operators';



@Injectable({  providedIn: 'root'})  

export class WindowScrollService {

  public scroll$:BehaviorSubject<number>=new BehaviorSubject<number>(0);;


  constructor(
   // @Inject(DOCUMENT) private document:any,
   // @Inject(PLATFORM_ID) private platformId: Object
  ){


//  this.scroll$.subscribe(res=> console.log(res))

 
    /*
    if(isPlatformBrowser(this.platformId)){
      this.scroll$ = fromEvent(window, 'scroll').pipe(
        map(event =>{
          return window.scrollY || this.document.documentElement.scrollTop;
        }),  
        share()
      );
    }
    else{
      //in non-browser environments, provide an empty observable so you can safely subscribe to scroll$
      this.scroll$ = empty();
      
    }*/
  }

}
