import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MusicComponent }      from './music/music.component';
import { SoftwareComponent }      from './software/software.component';
import { DashboardComponent }   from './dashboard/dashboard.component';
import { ClubeventsComponent }   from './music/clubevents/clubevents.component';
import { ReferencesComponent }   from './references/references.component';

import { ProductionComponent }   from './music/production/production.component';
import { VideoComponent }   from './music/video/video.component';
import { CommercialComponent }   from './music/commercial/commercial.component';
import { WebsitesComponent }   from './websites/websites.component';
import { YoutubeComponent } from './music/youtube/youtube.component'
import { WerbebannerComponent } from './werbebanner/werbebanner.component';


const routes: Routes = [
    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    { path: 'music', component: MusicComponent ,
    children: [
      { path: '', redirectTo: '/music/production', pathMatch: 'full' },
      {path: 'production', component: ProductionComponent},
      {path: 'video', component: VideoComponent}, 
      {path: 'youtube', component: YoutubeComponent},
 
     // {path: 'commercial', component: CommercialComponent}, 

      {path: 'clubevents', component: ClubeventsComponent}] 
  },
  
  { path: 'websites', component: WebsitesComponent },
  { path: 'werbebanner', component: WerbebannerComponent },

    { path: 'software', component: SoftwareComponent },
    { path: 'references', component: ReferencesComponent },

    { path: 'dashboard', component: DashboardComponent },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }






