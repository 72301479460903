import { Component, OnInit ,ChangeDetectorRef , } from '@angular/core';
import { VgAPI } from 'videogular2/core';
import { map } from 'rxjs/operators';

import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';

export interface IMedia {
  title: string;
  src: string;
  type: string;
}
@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  constructor(  
    private breakpointObserver: BreakpointObserver) { }

    iscolumn = 'column';
    $isHandset: Observable<boolean>= this.breakpointObserver.observe(
        [Breakpoints.Handset
            , Breakpoints.Small
            //, Breakpoints.Large
        ])
    .pipe(
      map((result) => {
          console.log(result);
          let resu;
        if(result.matches){
            resu= 'column';} else {
                resu= 'row'}


                this.iscolumn=resu;
            return result.matches;
    })
    );

    $isnotHandset: Observable<boolean>= this.breakpointObserver.observe(
        [Breakpoints.Handset
            //, Breakpoints.Large
        ])
    .pipe(
      map((result) => {
          console.log(result);
      
            return !result.matches;
    })
    );
  
  ngOnInit() {
  }


  playlist: Array<IMedia> = [
    {
        title: 'Admiral Sportwetten Spot 2017',
        src: './assets/music/video/ADMIRAL_TV-Spot201516.mp4',
        type: 'video/mp4'
    },
     {
        title: 'Admiral Sportwetten Spot 2015',
        src: './assets/music/video/ADMIRAL_TV-Spot201718.mp4',
        type: 'video/mp4'
    },
    {
       title: 'Neukurs Telefonschleife 2012',
       src: './assets/music/video/neukurs-telefonschleife2012.mp3',
       type: 'video/mp4'
   },
   {
      title: 'Neukurs Signation 2012',
      src: './assets/music/video/neukurs-signation2012.mp3',
      type: 'video/mp4'
  }
];

currentIndex = 0;
currentItem: IMedia = this.playlist[this.currentIndex];
api: VgAPI;



onPlayerReady(api: VgAPI) {
    this.api = api;

   // this.api.getDefaultMedia().subscriptions.loadedMetadata.subscribe(this.playVideo.bind(this));
   // this.api.getDefaultMedia().subscriptions.ended.subscribe(this.nextVideo.bind(this));
}

nextVideo() {
    this.currentIndex++;

    if (this.currentIndex === this.playlist.length) {
        this.currentIndex = 0;
    }

    this.currentItem = this.playlist[this.currentIndex];
}

playVideo() {
    this.api.play();
}

onClickPlaylistItem(item: IMedia, index: number) {
    this.currentIndex = index;
    this.currentItem = item;
}
}
