import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FullscreenpictureserviceService } from '../fullscreenpictureservice.service';

@Component({
  selector: 'app-fullscreenpicture',
  templateUrl: './fullscreenpicture.component.html',
  styleUrls: ['./fullscreenpicture.component.scss']
})
export class FullscreenpictureComponent implements OnInit {

  pictureUrl:string;
  pictureUrls:Array<string>;
showFullscreen:boolean;

  constructor(public fullscreenPictureService: FullscreenpictureserviceService) { 

    this.pictureUrl=""
    this.pictureUrls=[];

  }

  

  ngOnInit() {   
    this.fullscreenPictureService.pictureUrl.subscribe(value => {
      console.log(value);this.pictureUrl=value;
      
    })    
    this.fullscreenPictureService.pictureUrls.subscribe(value => {
      console.log(value);this.pictureUrls=value;
      this.showFullscreen=this.pictureUrls.length>0;
    })    

  }

hidePicture(){
  console.log("hide")
  this.fullscreenPictureService.hidePicture();
}

}
